import { createSlice } from "@reduxjs/toolkit";

const getMyIssueTitlesReducer = createSlice({
  name: "IssueTitles",
  initialState: {},
  reducers: {
    getMyIssueTitlesRequest: (state) => {
      state.loading = true;
    },
    getMyIssueTitlesSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.allTitles = action.payload;
      state.error = false;
      state.success = true;
    },
    getMyIssueTitlesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMyIssueTitlesReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getMyIssueTitlesRequest,
  getMyIssueTitlesSuccess,
  getMyIssueTitlesFailure,
  getMyIssueTitlesReset,
} = getMyIssueTitlesReducer.actions;
export default getMyIssueTitlesReducer.reducer;
