import { createSlice } from "@reduxjs/toolkit";

const productReviewGetReducer = createSlice({
	name: "productReviewGet",
	initialState: {},
	reducers: {
		ProductReviewGetRequest: (state) => {
			state.loading = true;
		},
		ProductReviewGetSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getReviewsall = action.payload;
			state.error=false;
			state.success = true;
		},
		ProductReviewGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		ProductReviewGetReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	ProductReviewGetRequest,
	ProductReviewGetSuccess,
	ProductReviewGetFailure,
	ProductReviewGetReset,
} = productReviewGetReducer.actions;
export default productReviewGetReducer.reducer;
