/** @format */
// import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HomeScreens from "./Screens/HomeScreen";
import FeedbackScreens from "./Screens/FeedbackScreen";
//import TourScreen from "./screens/TourScreen";
import LoginScreen from "./Screens/LoginScreen";
import RegisterScreen from "./Screens/RegisterScreen";
//import AccountScreen from "./screens/AccountScreen";
import BookingListScreen from "./Screens/BookingListScreen";
import AddSpecialityScreens from "./Screens/adminScreens/AddSpeciality";
import AdminLoginScreens from "./Screens/adminScreens/AdminLoginScreen";
import AddDoctorScreens from "./Screens/adminScreens/AddDoctor";
import AdminHomeScreens from "./Screens/adminScreens/AdminHomeScreen";
import AdminFeedbackScreens from "./Screens/adminScreens/AdminFeedbackScreen";
import ViewAppointmentScreen from "./Screens/ViewAppointments";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import AppointmentListScreen from "./Screens/adminScreens/AdminAppointmentsListScreen";
import UpdatePasswordScreen from "./Screens/UpdatepasswordScreen";
import SearchPatientsScreens from "./Screens/adminScreens/SearchPatients";
import OnSpotAppointmentsScreens from "./Screens/adminScreens/onSpotAppointment";
import EditPrescriptionScreen from "./Screens/doctorScreens/editPrescription";
import GetMyAppointmentsScreen from "./Screens/doctorScreens/getMyAppointments";
import DoctorLeavesScreen from "./Screens/doctorScreens/DoctorLeaves";
import { useSelector } from "react-redux";

export const SERVER_URL = "https://amh-hospital.onrender.com";

function App() {
	const { userInfo } = useSelector((state) => state.userLogin);
	const adminLogin = useSelector((state) => state.adminLogin);
	const { loading, error, adminInfo } = adminLogin;
	return (
		<div className="body1">
			<Router>
				<Header className="header" />
				<div className="main1">
					<Routes>
						<Route path="/" element={<HomeScreens />} exact />
						<Route path="/feedbacks" element={<FeedbackScreens />} exact />
						<Route path="/login" element={<LoginScreen />} />
						<Route path="/register" element={<RegisterScreen />} />
						<Route
							path="/booking"
							element={userInfo ? <BookingListScreen /> : <LoginScreen />}
						/>
						<Route
							path="/viewappointments"
							element={userInfo ? <ViewAppointmentScreen /> : <LoginScreen />}
						/>
						<Route
							path="/updatepassword"
							element={userInfo ? <UpdatePasswordScreen /> : <LoginScreen />}
						/>
						<Route
							path="/admin/addSpeciality"
							element={
								adminInfo ? <AddSpecialityScreens /> : <AdminLoginScreens />
							}
						/>
						<Route path="/admin/login" element={<AdminLoginScreens />} />
						<Route
							path="/admin/addDoctor"
							element={adminInfo ? <AddDoctorScreens /> : <AdminLoginScreens />}
						/>
						{/* <Route path='/admin/home' element={<AdminHomeScreens />} /> */}
						<Route
							path="/admin/feedbacks"
							element={
								adminInfo ? <AdminFeedbackScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/appointments"
							element={
								adminInfo ? <AppointmentListScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/searchPatients"
							element={
								adminInfo ? <SearchPatientsScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/bookappointment"
							element={
								adminInfo ? (
									<OnSpotAppointmentsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/editpres"
							element={<EditPrescriptionScreen />}
						/>
						<Route
							path="/doctor/myappointments"
							element={<GetMyAppointmentsScreen />}
						/>
						<Route path="/doctor/leaves" element={<DoctorLeavesScreen />} />
					</Routes>
				</div>

				<Footer className="footer" />
			</Router>
		</div>
	);
}

export default App;
