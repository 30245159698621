/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Errortemplate from '../Components/Errortemplate';
import Loader from '../Components/Loader';
import { Link } from 'react-router-dom';
import { login } from '../redux/userApiCalls';
import Button from 'react-bootstrap/Button';
// import { useToast } from '@chakra-ui/react';
// import { VStack } from '@chakra-ui/layout';
import { loginReset } from '../redux/userLogin';
import Alert from 'react-bootstrap/Alert';
import Message from '../Components/Message';

const LoginScreen = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);

	const [mobilenumber, setmobilenumber] = useState('');
	const [password, setPassword] = useState('');
	// const toast = useToast();
	const userLogin = useSelector((state) => state.userLogin);
	console.log(userLogin);
	const { loading, error, userInfo, success } = userLogin;
	console.log(userInfo, error);
	const togglePassword = document.querySelector('#togglePassword');
	const passwordToggler = document.querySelector('#password');
	useEffect(() => {
		if (userInfo) {
			console.log('111');
			history('/');
		}
	}, [history, userInfo]);
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(login(mobilenumber, password));
	};

	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(loginReset());
	};
	// togglePassword.addEventListener("click", function (e) {
	//   const type =password.getAttribute("type") === "password" ? "text" : "password";
	//   password.setAttribute("type", type);
	//   this.classList.toggle("bi-eye");
	// });

	return (
		<>
			{/* <main className="main">
				<div className="login-form">
					<h4 className="heading-secondary ma-bt-lg">Login your account</h4>
					
					{!error && (
						<form className="form form-group" onSubmit={submitHandler}>
							<div className="form__group">
								<label className="form__label" htmlFor="mobileno">
									Mobile number
								</label>
								<input
									className="form__input"
									id="mobileno"
									type="mobileno"
									placeholder="9999999999"
									required="required"
									onChange={(e) => setmobilenumber(e.target.value)}
								/>
							</div>

							<div className="form__group ma-bt-md">
								<label className="form__label" htmlFor="password">
									Password
								</label>
								<input
									className="form__input"
									id="password"
									type="password"
									placeholder="••••••••"
									required="required"
									minLength={8}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<Button type="submit" className="btn btn-success offset-md-5">Login</Button>
								<h6 className="col-5 offset-md-4 mt-5">Don't have an account?</h6>
							<a role="button" href={"/register"} className="btn offset-md-5 btn-danger col-2">
								Register   
								</a>
						</form>
					)}
				</div>
			</main> */}
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{success && (
				<Message variant='success'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{'Succesfully logged in'}
				</Message>
			)}
			<div className='row row-content'>
				<div className='col-12 col-md-4 offset-md-4 loginForm'>
					<h2 className='loginHeader'>Login</h2>
					<form
						className='form-group'
						novalidate
						id='user-form'
						onSubmit={submitHandler}>
						<div className='form-group'>
							<label for='username' className='col-md-12 col-form-label'>
								<b>
									Mobile Number <span className='text-danger'>*</span>
								</b>
							</label>
							<div className='col-md-12'>
								<input
									type='tel'
									className='form-control'
									id='username'
									name='username'
									placeholder='9999999999'
									required
									
									onChange={(e) => setmobilenumber(e.target.value)}
									minlength='10'
                                    maxLength='10'
									pattern='^[0-9]*$'
								/>
							</div>
						</div>
						<div className='form-group'>
							<label for='password' className='col-md-12 col-form-label'>
								<b>
									Password <span className='text-danger'>*</span>
								</b>
							</label>
							<div className='col-md-12 flex321 '>
								<input
									className='form-control'
									id='password'
									name='password'
									placeholder='••••••••'
									required
									onChange={(e) => setPassword(e.target.value)}
									minlength='8'
									type={show ? 'text' : 'password'}
								/>
								<button type='button' className='close' onClick={handleClick}>
									{show == 1 ? (
										<i class='fa fa-eye ppppp' aria-hidden='true'></i>
									) : (
										<i class='fa fa-eye-slash ppppp' aria-hidden='true'></i>
									)}
								</button>
							</div>
						</div>
						<div className='form-group'>
							<button
								type='submit'
								className='btn btn-success mt-5 offset-2 col-8'>
								<b>Log in</b>
							</button>
						</div>
						<h6 className='col-7 offset-3 mt-5'>Don't have an account?</h6>
						<a
							role='button'
							href={'/register'}
							className='btn offset-2 btn-danger col-8'>
							Register
						</a>
					</form>
				</div>
			</div>
		</>
	);
};

export default LoginScreen;
