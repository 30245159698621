/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editPrescription = createSlice({
	name: 'prescription',
	initialState: {},
	reducers: {
		editPrescriptionStart: (state) => {
			state.loading = true;
		},
		editPrescriptionSuccess: (state, action) => {
			state.loading = false;
			state.prescriptionInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		editPrescriptionFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editPrescriptionReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editPrescriptionStart,
	editPrescriptionSuccess,
	editPrescriptionFailure,
	editPrescriptionReset,
} = editPrescription.actions;
export default editPrescription.reducer;
