import Chart from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { createSpeciality } from "../../redux/adminRedux/adminApiCalls";
// import createPlotlyComponent from 'react-plotlyjs';
//See the list of possible plotly bundles at https://github.com/plotly/plotly.js/blob/master/dist/README.md#partial-bundles or roll your own
// import Plotly from 'plotly.js/dist/plotly-cartesian';
// import Plotly from 'plotly.js';
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Message from '../../Components/Message'
Chart.register(CategoryScale);

// import { Data } from "./Data";

const Data = [
  {
    id: 1,
    date: 3,
    count: 120,
  },
  {
    id: 2,
    date: 4,
    count: 130,
  },
  {
    id: 3,
    date: 4,
    count: 150,
    userLost: 555,
  },
  {
    id: 4,
    date: 5,
    count: 140,
    userLost: 4555,
  },
  {
    id: 5,
    date: 6,
    count: 145,
    userLost: 234,
  },
  {
    id: 6,
    date: 7,
    count: 150,
    userLost: 234,
  },
  {
    id: 7,
    date: 8,
    count: 160,
    userLost: 234,
  },
  {
    id: 8,
    date: 9,
    count: 165,
    userLost: 234,
  },
];

const AdminHomeScreens = () => {
  const adminLogin = useSelector((state) => state.adminLogin);
  console.log(adminLogin?.adminInfo?.data?.monthly_counter)

  const [chartData, setChartData] = useState({
    labels: Data.map((data) => data.date),
    datasets: [
      {
        label: "Users Gained ",
        data: Data.map((data) => data.count),
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  console.log(Data);

  return (
    <>
      <Meta />
      <h1 className="offset-md-5">Statistics</h1>
      <Line
        // height="100px"
        // width="100px"
        // width={"30%"}
        // style={myStyle}

        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Patients data between 3-9",
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </>
  );
};

export default AdminHomeScreens;
