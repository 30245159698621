/** @format */

import { createSlice } from '@reduxjs/toolkit';
const userItemsFromStorage = JSON.parse(localStorage.getItem('persist:root'))
	? JSON.parse(localStorage.getItem('persist:root'))?.userRegister
	: {};
console.log(userItemsFromStorage);
const userDeleteSlice = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		DeleteStart: (state) => {
			state.loading = true;
		},
		DeleteSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = null;
			state.success = true;
		},
		DeleteFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		userDeleteProfileReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	DeleteStart,
	DeleteSuccess,
	DeleteFailure,
	userDeleteProfileReset,
} = userDeleteSlice.actions;
export default userDeleteSlice.reducer;
