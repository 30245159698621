import { createSlice } from "@reduxjs/toolkit";

const TimeSlotGetReducer = createSlice({
	name: "TimeSlotGet",
	initialState: {},
	reducers: {
		TimeSlotGetRequest: (state) => {
			state.loading = true;
		},
		TimeSlotGetSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.allTimeSlots = action.payload;
			state.error=false;
			state.success = true;
		},
		TimeSlotGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		TimeSlotGetReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	TimeSlotGetRequest,
	TimeSlotGetSuccess,
	TimeSlotGetFailure,
	TimeSlotGetReset,
} = TimeSlotGetReducer.actions;
export default TimeSlotGetReducer.reducer;
