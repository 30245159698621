/** @format */
import Message from '../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
	getSpecialities,
	getDoctorFromSpeciality,
	getTimeslots,
} from '../../redux/userApiCalls';
import {
	getDoctorsAppointmentsForDate,
	changeToVisited,
	changeToOnHold,
	changeSlot,
} from '../../redux/adminRedux/adminApiCalls';
// import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import { doctorBookingGetReset } from '../../redux/adminRedux/getBooking';
import { changeVisitedReset } from '../../redux/adminRedux/changeVisited';
import { changeOnHoldReset } from '../../redux/adminRedux/changeOnHold';
import { changeSlotReset } from '../../redux/adminRedux/changeSlot';
import Form from 'react-bootstrap/Form';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const AppointmentListScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	// Accordian Collapse
	const [open, setOpen] = useState('1');
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	//Date Format
	const days = [];


	const currDate = new Date(); // Get current date
	for (let i = 0; i < 7; i++) {
		const currentDate = new Date(
		currDate.getFullYear(),
		currDate.getMonth(),
		currDate.getDate() + i
		);
		const date = currentDate.getDate();
		const month = currentDate.getMonth() + 1; // Month index starts from 0
		const year = currentDate.getFullYear();
		const formattedDate = date + " / " + month + " / " + year;
		days.push(formattedDate);
	}

	//Retrieving Specialities Data
	useEffect(() => {
		dispatch(getSpecialities());
	}, [dispatch]);
	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	let allSpecialities = [];
	let allDoctors = [];
	if (getSpecialityAll) {
		console.log(getSpecialityAll);
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);

	//Retrieving Doctors Data from Specialities
	const { getDoctorfromSpeciality } = useSelector((state) => state.getDoctor);
	if (getDoctorfromSpeciality) {
		if (getDoctorfromSpeciality.data[0]) {
			console.log(getDoctorfromSpeciality.data);
			if (getDoctorfromSpeciality.data[0].doctorsArray) {
				for (
					let i = 0;
					i < getDoctorfromSpeciality.data[0].doctorsArray.length;
					i++
				) {
					allDoctors.push(getDoctorfromSpeciality.data[0].doctorsArray[i][0]);
				}
			}
		}
	}
	console.log(allDoctors);

	//Retrieving Bookings Data of selected Doctor
	const data1 = useSelector((state) => state.getDoctorBooking);
	const { getBookingsall, loading, error, success } = data1;
	console.log(getBookingsall?.data?.data);

	const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
	let timeSlots = [];
	let isEmpty = true;
	for (let i = 0; i < allTimeSlots?.length; i++) {
		isEmpty = allTimeSlots[i].isEmpty;
		timeSlots.push({
			isEmpty: isEmpty,
			timeSlot: allTimeSlots[i].timeSlot,
			curCount: allTimeSlots[i].curCount,
			maxCount: allTimeSlots[i].maxCount,
		});
	}
	console.log(timeSlots);

	//Speciality dropdown
	const [specialityOption, setSpecialityOption] = useState('Choose Specialty');
	const [specialityId, setSpecialityId] = useState('');
	const handleSpecialitySelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split('/');
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption('Choose Doctor');
		setDateOption('Choose Date');
		setDateSelected(false);
		// slotArray = [];
		// slotArrayVisited = [];
		dispatch(getDoctorFromSpeciality(arr[0]));
		console.log();
	};

	//Doctor dropdown
	const [doctorOption, setDoctorOption] = useState('Choose Doctor');
	const [doctorId, setDoctorId] = useState('');
	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split('/');
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		setDateSelected(false);
		// slotArray = [];
		// slotArrayVisited = [];
	};

	//Date dropdown
	const [dateOption, setDateOption] = useState('Choose Date');
	const [dateSelected, setDateSelected] = useState(false);
	const handleDateSelect = (eventKey) => {
		setDateOption(eventKey);
		setDateSelected(false);
		// slotArray = [];
		// slotArrayVisited = [];
	};

	//Search button function
	const submitHandler = async () => {
		if (specialityOption == 'Choose Specialty') {
			alert('Speciality needs to be selected first, Please select Speciality');
		} else if (doctorOption == 'Choose Doctor') {
			alert('Doctor needs to be selected first, Please select Doctor');
		} else if (dateOption == 'Choose Date') {
			alert('Date needs to be selected first, Please select Date');
		} else {
			if (dateOption !== 'Choose Date') setDateSelected(true);
			const tempArr = dateOption.split('/');
			const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	};

	const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension ='.xlsx';
	const exportToExcel = async () =>{
		console.log(slotVisitedArr);
		let tempArr=[]
		for(let i=0;i<slotVisitedArr.length;i++){
			for(let j=1;j<slotVisitedArr[i].length;j++){
				const obj = {
					"Patient Name": slotVisitedArr[i][j].name,
					"Mobile No": slotVisitedArr[i][j].mobileNo,
					"Slot": slotVisitedArr[i][j].slot,
        		};
				tempArr.push(obj);
			}
		}
		console.log(tempArr+"dataaaaaa")
		console.log(specialityOption + "_" + doctorOption + "_" + days[0]);
		const ws = XLSX.utils.json_to_sheet(tempArr);
		const wb={Sheets:{'data':ws},SheetNames:['data']};
		const excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'});
		const data = new Blob([excelBuffer],{type:fileType});
		FileSaver.saveAs(data, specialityOption +"_"+ doctorOption+"_"+days[0]);
		console.log('saved')
	}
	const handleChangeToVisited = (id) => {
		console.log(id);
		dispatch(changeToVisited(id));
	};
	const handleChangeToOnHold = (id) => {
		setDateSelected(false);
		dispatch(changeToOnHold(id));
		const tempArr = dateOption.split('/');
		const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
		dispatch(
			getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
		);
	};
	const [selectedPatientFromOnHold, setSelectedPatientFromOnHold] = useState("");
	const openModel = (_id) => {
		setSelectedPatientFromOnHold(_id)
		setLgShow(true);
		dispatch(getTimeslots(doctorOption, specialityOption, dateOption));
	};
	const [changeSlotOption, setChangeSlotOption] = useState('');
	const handleChangeToSomeSlot = () => {
		if (changeSlotOption === '') {
			alert('Please select a vaild slot');
		} else {
			dispatch(changeSlot(selectedPatientFromOnHold, changeSlotOption));
		}

	};

	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(doctorBookingGetReset());
	};
	const changeVisitedData = useSelector((state) => state.changeVisited);
	const changeError = changeVisitedData.error;
	const changeloading = changeVisitedData.loading;
	const changesuccess = changeVisitedData.success;
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(changeVisitedReset());
	};
	const changeOnHoldData = useSelector((state) => state.changeOnHold);
	const changeOnHoldError = changeOnHoldData.error;
	const changeOnHoldloading = changeOnHoldData.loading;
	const changeOnHoldsuccess = changeOnHoldData.success;
	const [showPopup2, setShowPopup2] = React.useState(false);

	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log('error');
		dispatch(changeOnHoldReset());
	};
	const changeSlotData = useSelector((state) => state.changeSlot);
	const changeSlotError = changeSlotData.error;
	const changeSlotloading = changeSlotData.loading;
	const changeSlotsuccess = changeSlotData.success;
	const [showPopup3, setShowPopup3] = React.useState(false);

	const toggleShowInfoPopup3 = (e) => {
		setShowPopup3(!showPopup3);
		e.preventDefault();
		console.log('error');
		dispatch(changeSlotReset());
	};

  // calling the function
  // passing array argument

	useEffect(() => {
		if (
			specialityOption !== 'Choose Speciality' &&
			doctorOption !== 'Choose Doctor' &&
			dateOption !== 'Choose Date'
		) {
			if (dateOption !== 'Choose Date') setDateSelected(true);
			setLgShow(false);
			const tempArr = dateOption.split('/');
			const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	}, [history, dispatch, changeOnHoldsuccess, changeOnHoldError]);
	
	useEffect(() => {
		if (
			specialityOption !== 'Choose Speciality' &&
			doctorOption !== 'Choose Doctor' &&
			dateOption !== 'Choose Date'
		) {
			if (dateOption !== 'Choose Date') setDateSelected(true);
			const tempArr = dateOption.split('/');
			const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	}, [history, dispatch, changeSlotsuccess, changeSlotError]);

	
	const [slotVisitedArr, setSlotVisitedArr] = useState([]);
	const [slotNotVisitedArr, setNotSlotVisitedArr] = useState([]);
	const [slotHoldArr, setSlotHoldArr] = useState([]);
	
	  useEffect(() => {
      var slotArrayVisited = [];
      var tempVisitedArr = [];
      var slotArrayNotVisited = [];
      var tempNotVisitedArr = [];

      var j = 1;
      var slotArrHold = [];

      var tempNotVisitedArrFromHold = [];
      var slotArrayNotVisitedFromHold = [];

      console.log(slotArrayNotVisited);
      console.log(slotArrayNotVisitedFromHold);
      for (let i = 0; i < getBookingsall?.data?.data?.length; i++) {
        console.log(slotArrayNotVisitedFromHold);
        if (
          getBookingsall?.data?.data[i]?.visited === 1 &&
          !tempVisitedArr.includes(getBookingsall?.data?.data[i]?.slot)
        ) {
          slotArrayVisited.push([getBookingsall?.data?.data[i]?.slot]);
          tempVisitedArr.push(getBookingsall?.data?.data[i]?.slot);
        }
        if (
          getBookingsall?.data?.data[i]?.slot !== "onHold" &&
          getBookingsall?.data?.data[i]?.visited !== 1 &&
          getBookingsall?.data?.data[i]?.onHold === 0 &&
          !tempNotVisitedArr.includes(getBookingsall?.data?.data[i]?.slot)
        ) {
          console.log(getBookingsall?.data?.data[i]?.slot, slotArrayNotVisited);
          slotArrayNotVisited.push([getBookingsall?.data?.data[i]?.slot]);
          console.log(slotArrayNotVisited);
          tempNotVisitedArr.push(getBookingsall?.data?.data[i]?.slot);
          console.log(tempNotVisitedArr);
        }
        if (getBookingsall?.data?.data[i]?.slot === "onHold") {
          slotArrHold.push({
            _id: getBookingsall?.data?.data[i]?._id,
            name: getBookingsall?.data?.data[i]?.name,
            slot: getBookingsall?.data?.data[i]?.slot,
            slotCount: getBookingsall?.data?.data[i]?.slotCount,
            visited: getBookingsall?.data?.data[i]?.visited,
            mobileNo: getBookingsall?.data?.data[i]?.userPhone,
            onHold: getBookingsall?.data?.data[i]?.onHold,
          });
        }
        if (
          getBookingsall?.data?.data[i]?.onHold !== 0 &&
          getBookingsall?.data?.data[i]?.visited !== 1 &&
          !tempNotVisitedArrFromHold.includes(
            getBookingsall?.data?.data[i]?.slot
          )
        ) {
          console.log(getBookingsall?.data?.data[i]?.slot);
          slotArrayNotVisitedFromHold.push([
            getBookingsall?.data?.data[i]?.slot,
          ]);
          tempNotVisitedArrFromHold.push(getBookingsall?.data?.data[i]?.slot);
        }
      }
      console.log(slotArrayNotVisited);
      console.log(slotArrayNotVisitedFromHold);
      console.log(slotArrayNotVisited);

      console.log(slotArrayVisited);
      console.log(slotArrayNotVisited);
      console.log(slotArrayNotVisitedFromHold);
      //loop for all bookings
      for (let i = 0; i < getBookingsall?.data?.data?.length; i++) {
        //filtering bookings and checking if booking is already visited(visited=1)
        if (getBookingsall?.data?.data[i]?.visited === 1) {
          for (let j = 0; j < slotArrayVisited?.length; j++) {
            //checking which slot will the current booking(getBookingsall?.data?.data[i]?) slot belong to
            if (
              slotArrayVisited[j][0] === getBookingsall?.data?.data[i]?.slot
            ) {
              slotArrayVisited[j].push({
                _id: getBookingsall?.data?.data[i]?._id,
                name: getBookingsall?.data?.data[i]?.name,
                slot: getBookingsall?.data?.data[i]?.slot,
                slotCount: getBookingsall?.data?.data[i]?.slotCount,
                visited: getBookingsall?.data?.data[i]?.visited,
                mobileNo: getBookingsall?.data?.data[i]?.userPhone,
                onHold: getBookingsall?.data?.data[i]?.onHold,
              });
            }
          }
        }
        if (
          getBookingsall?.data?.data[i]?.slot !== "onHold" &&
          getBookingsall?.data?.data[i]?.visited !== 1 &&
          getBookingsall?.data?.data[i]?.onHold === 0
        ) {
          for (let j = 0; j < slotArrayNotVisited?.length; j++) {
            //checking which slot will the current booking(getBookingsall?.data?.data[i]?) slot belong to
            if (
              slotArrayNotVisited[j][0] === getBookingsall?.data?.data[i]?.slot
            ) {
              slotArrayNotVisited[j].push({
                _id: getBookingsall?.data?.data[i]?._id,
                name: getBookingsall?.data?.data[i]?.name,
                slot: getBookingsall?.data?.data[i]?.slot,
                slotCount: getBookingsall?.data?.data[i]?.slotCount,
                visited: getBookingsall?.data?.data[i]?.visited,
                mobileNo: getBookingsall?.data?.data[i]?.userPhone,
                onHold: getBookingsall?.data?.data[i]?.onHold,
              });
            }
          }
        }
        if (
          getBookingsall?.data?.data[i]?.onHold !== 0 &&
          getBookingsall?.data?.data[i]?.visited !== 1
        ) {
          console.log(getBookingsall?.data?.data[i]?.onHold);
          for (let j = 0; j < slotArrayNotVisitedFromHold?.length; j++) {
            //checking which slot will the current booking(getBookingsall?.data?.data[i]?) slot belong to
            if (
              slotArrayNotVisitedFromHold[j][0] ===
              getBookingsall?.data?.data[i]?.slot
            ) {
              slotArrayNotVisitedFromHold[j].push({
                _id: getBookingsall?.data?.data[i]?._id,
                name: getBookingsall?.data?.data[i]?.name,
                slot: getBookingsall?.data?.data[i]?.slot,
                slotCount: getBookingsall?.data?.data[i]?.slotCount,
                visited: getBookingsall?.data?.data[i]?.visited,
                mobileNo: getBookingsall?.data?.data[i]?.userPhone,
                onHold: getBookingsall?.data?.data[i]?.onHold,
              });
            }
          }
        }
      }
      for(let i=0;i<slotArrayNotVisitedFromHold?.length;i++){
        let timeSlot = slotArrayNotVisitedFromHold[i][0];
        let tempArr123 = slotArrayNotVisitedFromHold[i].slice(1,slotArrayNotVisitedFromHold[i].length + 1);
        tempArr123.sort(function (a, b) {
          return a["onHold"] - b["onHold"];
        });
        slotArrayNotVisitedFromHold[i] = [timeSlot];
        slotArrayNotVisitedFromHold[i] =
        slotArrayNotVisitedFromHold[i].concat(tempArr123);
      }
	  console.log(slotArrayNotVisitedFromHold);

      slotArrayNotVisitedFromHold.sort(function (a, b) {
        return parseInt(a[0].split(".")[0]) - parseInt(b[0].split(".")[0]);
      });
      for (let j = 0; j < slotArrayNotVisitedFromHold?.length; j++) {
        let flag = 1;
        for (let i = 0; i < slotArrayNotVisited?.length; i++) {
          if (slotArrayNotVisitedFromHold[j][0] === slotArrayNotVisited[i][0]) {
            slotArrayNotVisited[i] = slotArrayNotVisited[i].concat(
              slotArrayNotVisitedFromHold[j]
                .slice(1, slotArrayNotVisitedFromHold[j].length + 1)
                .reverse()
            );
            console.log(slotArrayNotVisited[i]);
            flag = 0;
            break;
          }
        }
        if (flag == 1) {
          slotArrayNotVisited.push(slotArrayNotVisitedFromHold[j]);
        }
      }
      console.log(slotArrayVisited);
      console.log(slotArrayNotVisited);
      console.log(slotArrHold);
      slotArrayVisited.sort(function (a, b) {
        return parseInt(a[0].split(".")[0]) - parseInt(b[0].split(".")[0]);
      });
      slotArrayNotVisited.sort(function (a, b) {
        return parseInt(a[0].split(".")[0]) - parseInt(b[0].split(".")[0]);
      });
      console.log(slotArrayVisited);
      const temp_slotArrayVisited = slotArrayVisited;
      const temp_slotArrayNotVisited = slotArrayNotVisited;
      const temp_slotArrHold = slotArrHold;

      setSlotVisitedArr(temp_slotArrayVisited);
      setSlotHoldArr(temp_slotArrHold);
      setNotSlotVisitedArr(temp_slotArrayNotVisited);
    }, [
			history,
			dispatch,
			getBookingsall,
			changesuccess,
			changeError,
			changeOnHoldsuccess,
			changeOnHoldError,
			changeSlotsuccess,
			changeSlotError,
		]);
		useEffect(() => {
		if (
			specialityOption !== 'Choose Speciality' &&
			doctorOption !== 'Choose Doctor' &&
			dateOption !== 'Choose Date'
		) {
			if (dateOption !== 'Choose Date') setDateSelected(true);
			const tempArr = dateOption.split('/');
			const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	}, [history, dispatch, changesuccess, changeError]);


	return (
    <>
      <Meta />
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}
      {changeError && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {changeError}
        </Message>
      )}
      {changeloading && <Loader />}
      {changesuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Succesfully changed to visited"}
        </Message>
      )}
      {changeOnHoldError && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup2}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {changeOnHoldError}
        </Message>
      )}
      {changeOnHoldloading && <Loader />}
      {changeOnHoldsuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup2}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Succesfully changed to on hold"}
        </Message>
      )}
      {changeSlotError && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup3}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {changeSlotError}
        </Message>
      )}
      {changeSlotloading && <Loader />}
      {changeSlotsuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup3}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Succesfully changed the slot"}
        </Message>
      )}
      <h1 className="offset-md-5">Appointments </h1>
      <div className="row mt-5 mb-3">
        <h6 className="col-2 offset-md-1">
          Select the filters{" "}
          <span className="text-danger">
            <b>*</b>
          </span>
        </h6>
        <DropdownButton
          className="col-2 btn"
          key="down-centered"
          id={`dropdown-button-drop-down-centered`}
          drop="down-centered"
          variant="success"
          title={specialityOption}
          onSelect={handleSpecialitySelect}
        >
          {allSpecialities.map((specialities, index) => (
            <Dropdown.Item
              eventKey={specialities.specialization + "/" + specialities._id}
            >
              {specialities.specialization}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <DropdownButton
          className="col-2 btn"
          key="down-centered"
          id={`dropdown-button-drop-down-centered`}
          drop="down-centered"
          variant="success"
          title={doctorOption}
          onSelect={handleDoctorSelect}
        >
          {allDoctors.map((doctors, index) => (
            <Dropdown.Item eventKey={doctors.name + "/" + doctors._id}>
              {doctors.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <DropdownButton
          className="col-2 btn"
          key="down-centered"
          id={`dropdown-button-drop-down-centered`}
          drop="down-centered"
          variant="success"
          title={dateOption}
          onSelect={handleDateSelect}
        >
          {days.map((day, index) => (
            <Dropdown.Item eventKey={day}>{day}</Dropdown.Item>
          ))}
        </DropdownButton>
        <Button
          id="searchButton"
          className="col-2 mt-2 btn-primary"
          onClick={submitHandler}
        >
          Search
        </Button>
      </div>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3 container"
      >
        <Tab eventKey="home" title="Patients to be visited">
          <Container>
            {dateSelected &&
              slotNotVisitedArr.map((slot1, index) => (
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem>
                    <AccordionHeader targetId={index}>
                      {slot1[0]}
                    </AccordionHeader>
                    <AccordionBody accordionId={index}>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th scope="col">Patient No</th>
                            <th scope="col">Patient Name</th>
                            <th scope="col">Patient Mobile Number</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        {slot1?.slice(1, slot1.length + 1).map(
                          (
                            {
                              _id,
                              name,
                              slot, //onhold
                              slotCount, // onhold:1
                              visited,
                              mobileNo,
                              onHold,
                            },
                            sno
                          ) => (
                            <tbody>
                              <tr>
                                <td></td>
                                <td>{sno + 1}</td>
                                <td>{name}</td>
                                <td>{mobileNo}</td>
                                <td>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleChangeToVisited(_id)}
                                  >
                                    <i className="fa fa-check"></i>
                                  </button>
                                  {"   "}{" "}
                                  {onHold === 0 ? (
                                    <button
                                      className="btn btn-warning"
                                      onClick={() => handleChangeToOnHold(_id)}
                                    >
                                      <i className="fa fa-pause"></i>
                                    </button>
                                  ) : (
                                    <div></div>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              ))}
          </Container>
          <Container>
            {dateSelected ? (
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    Patients On Hold
                  </AccordionHeader>

                  <AccordionBody accordionId="1">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th scope="col">Patient No</th>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Patient Mobile Number</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dateSelected &&
                          slotHoldArr.map(
                            (
                              { _id, name, slot, slotCount, visited, mobileNo },
                              sno
                            ) =>
                              visited === 2 ? (
                                <tr>
                                  <td>{sno + 1}</td>
                                  <td>{name}</td>
                                  <td>{mobileNo}</td>
                                  <td>
                                    {"   "}{" "}
                                    {/* <button
																className='btn btn-primary'
																onClick={() => handleChangeToSomeSlot(_id)}>
																<i className='fa fa-arrow-up'></i>
															</button> */}
                                    <Button
                                      className="btn btn-primary"
                                      onClick={() => openModel(_id)}
                                    >
                                      <i className="fa fa-arrow-up"></i>
                                    </Button>
                                    <Modal
                                      size="xl"
                                      show={lgShow}
                                      onHide={() => setLgShow(false)}
                                      aria-labelledby="example-modal-sizes-title-xl"
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title id="example-modal-sizes-title-xl">
                                          Select Slot
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <div className="row offset-md-2">
                                          {timeSlots.map((timeslots, index) =>
                                            timeslots.isEmpty ? (
                                              <Form.Check
                                                onChange={() =>
                                                  setChangeSlotOption(
                                                    timeslots.timeSlot
                                                  )
                                                }
                                                className="btn btn-success col-md-3 m-1 col-10"
                                                type="radio"
                                                id={timeslots.timeSlot}
                                                label={`${timeslots.timeSlot} slot no: ${timeslots.curCount} `}
                                                checked={
                                                  changeSlotOption ===
                                                  timeslots.timeSlot
                                                }
                                              />
                                            ) : (
                                              <Button
                                                className="btn btn-danger col-md-3 m-1"
                                                disabled
                                              >
                                                {timeslots.timeSlot} Not
                                                avaliable
                                              </Button>
                                            )
                                          )}
                                        </div>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          onClick={() =>
                                            handleChangeToSomeSlot()
                                          }
                                          className="btn btn-primary"
                                        >
                                          Submit
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </td>
                                </tr>
                              ) : (
                                <div></div>
                              )
                          )}
                      </tbody>
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            ) : (
              <div> </div>
            )}
          </Container>
        </Tab>
        <Tab eventKey="profile" title="Visited Patients">
          <Button onClick={(e)=>exportToExcel()}>
			Copy to excel
		  </Button>
          <Container>
            {slotVisitedArr.map((slot1, index) => (
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId={index}>{slot1[0]}</AccordionHeader>
                  <AccordionBody accordionId={index}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th scope="col">Patient No</th>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Patient Mobile Number</th>
                        </tr>
                      </thead>
                      {slot1
                        ?.slice(1, slot1.length + 1)
                        .map(
                          (
                            { _id, name, slot, slotCount, visited, mobileNo },
                            sno
                          ) => (
                            <tbody>
                              <tr>
                                <td></td>
                                <td>{sno + 1}</td>
                                <td>{name}</td>
                                <td>{mobileNo}</td>
                              </tr>
                            </tbody>
                          )
                        )}
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            ))}
          </Container>
        </Tab>
      </Tabs>
    </>
  );
};

export default AppointmentListScreen;