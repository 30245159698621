/** @format */

import { createSlice } from "@reduxjs/toolkit";

const blockSlot = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    blockSlotStart: (state) => {
      state.loading = true;
    },
    blockSlotSuccess: (state, action) => {
      state.loading = false;
      state.block = action.payload;
      state.error = false;
      state.success = true;
    },
    blockSlotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    blockSlotReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  blockSlotStart,
  blockSlotSuccess,
  blockSlotFailure,
  blockSlotReset,
} = blockSlot.actions;
export default blockSlot.reducer;
