import { createSlice } from "@reduxjs/toolkit";

const viewPatientHistoryReducer = createSlice({
  name: "IssueTitles",
  initialState: {},
  reducers: {
    viewPatientHistoryRequest: (state) => {
      state.loading = true;
    },
    viewPatientHistorySuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.viewPatientHistory = action.payload;
      state.error = false;
      state.success = true;
    },
    viewPatientHistoryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    viewPatientHistoryReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  viewPatientHistoryRequest,
  viewPatientHistorySuccess,
  viewPatientHistoryFailure,
  viewPatientHistoryReset,
} = viewPatientHistoryReducer.actions;
export default viewPatientHistoryReducer.reducer;
