import { createSlice } from "@reduxjs/toolkit";

const getReviewsGetReducer = createSlice({
	name: "getReviewsGet",
	initialState: {},
	reducers: {
		getReviewsGetRequest: (state) => {
			state.loading = true;
		},
		getReviewsGetSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getReviewsall = action.payload;
			state.error=false;
			state.success=true
		},
		getReviewsGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getReviewsGetReset: (state) => {
			return { error:false,success:false};
		},
	},
});

export const {
	getReviewsGetRequest,
	getReviewsGetSuccess,
	getReviewsGetFailure,
	getReviewsGetReset,
} = getReviewsGetReducer.actions;
export default getReviewsGetReducer.reducer;
