/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createLeave = createSlice({
  name: "createLeave",
  initialState: {},
  reducers: {
    createLeaveStart: (state) => {
      state.loading = true;
    },
    createLeaveSuccess: (state, action) => {
      state.loading = false;
      state.Leave = action.payload;
      state.error = false;
      state.success = true;
    },
    createLeaveFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createLeaveReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  createLeaveStart,
  createLeaveSuccess,
  createLeaveFailure,
  createLeaveReset,
} = createLeave.actions;
export default createLeave.reducer;
