import { createSlice } from "@reduxjs/toolkit";

const reviewUpdateReducer = createSlice({
	name: "reviewUpdate",
	initialState: {},
	reducers: {
		reviewUpdateRequest: (state) => {
			return { loading: true };
		},
		reviewUpdateSuccess: (state, action) => {
			return { loading: false, success: true, error:false };
		},
		reviewUpdateFailure: (state, action) => {
			return { loading: false, error: action.payload };
		},
		reviewUpdateReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	reviewUpdateRequest,
	reviewUpdateSuccess,
	reviewUpdateFailure,
	reviewUpdateReset,
} = reviewUpdateReducer.actions;
export default reviewUpdateReducer.reducer;