/** @format */

import { createSlice } from "@reduxjs/toolkit";

const unBlockSlot = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    unBlockSlotStart: (state) => {
      state.loading = true;
    },
    unBlockSlotSuccess: (state, action) => {
      state.loading = false;
      state.unBlock = action.payload;
      state.error = false;
      state.success = true;
    },
    unBlockSlotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    unBlockSlotReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  unBlockSlotStart,
  unBlockSlotSuccess,
  unBlockSlotFailure,
  unBlockSlotReset,
} = unBlockSlot.actions;
export default unBlockSlot.reducer;
