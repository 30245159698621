/** @format */

import { createSlice } from '@reduxjs/toolkit';

const deleteBooking = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		deleteBookingStart: (state) => {
			state.loading = true;
		},
		deleteBookingSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteBookingFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteBookingReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	deleteBookingStart,
	deleteBookingSuccess,
	deleteBookingFailure,
	deleteBookingReset,
} = deleteBooking.actions;
export default deleteBooking.reducer;
