/** @format */
import { persistor } from '../store';
import { loginStart, loginFailure, loginSuccess, logout } from './doctorLogin';
import {SERVER_URL} from "../../App"
import {
	getMyAppointmentsFailure,
	getMyAppointmentsRequest,
	getMyAppointmentsSuccess,
} from './getMyAppointments';
import {
	getMyTimeSlotGetFailure,
	getMyTimeSlotGetRequest,
	getMyTimeSlotGetSuccess,
} from './getMyTimeslots';
import {
  createLeaveSuccess,
  createLeaveFailure,
  createLeaveStart,
} from "./createLeave";
import {
  blockSlotStart,
  blockSlotSuccess,
  blockSlotFailure,
} from "./blockSlots";
import {
  unBlockSlotStart,
  unBlockSlotSuccess,
  unBlockSlotFailure,
} from "./unBlockSlots";
import axios from 'axios';

export const login = (username, password) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(loginStart());

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/login`,
			{ username, password },
			config
		);
		console.log(data);
		localStorage.setItem('token-user', data.token);
		// localStorage.setItem("name", data.user.name);
		localStorage.setItem('doctorInfo', JSON.stringify(data));

		// console.log(data.user);
		dispatch(loginSuccess(data));
	} catch (error) {
		dispatch(
			loginFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const logout1 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem('userInfo');
	localStorage.removeItem('adminInfo');
  localStorage.removeItem('doctorInfo');
	localStorage.removeItem('token-user');
	localStorage.removeItem('persist:root');
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const getMyAppointments =
	(speciality, doctor, date) => async (dispatch) => {
		try {
			console.log(speciality + ' ' + doctor + '  ' + date);
			//loader function is called from getMyAppointmentss.js file
			dispatch(getMyAppointmentsRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			console.log(
				`${SERVER_URL}/api/v1/doctor/getMyAppointments/${speciality}/${doctor}/${date}`
			);
			//get request getMyAppointments function from doctor routes
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getMyAppointments/${speciality}/${doctor}/${date}`,
				config
			);

			console.log(data);
			dispatch(getMyAppointmentsSuccess(data));
		} catch (error) {
			dispatch(
				getMyAppointmentsFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getMyTimeslots = (name, speciality, date) => async (dispatch) => {
	try {
		console.log('11223344');
		console.log(name, speciality, date);
		const tempArr = date.split('/');
		const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
		dateModified.trim();
		console.log(dateModified);
		dispatch(getMyTimeSlotGetRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/booking/getTimeSlots/${name}/${speciality}/${dateModified}`,
			config
		);
		console.log(data.data.docs);
		dispatch(getMyTimeSlotGetSuccess(data.data.docs));
	} catch (error) {
		dispatch(
			getMyTimeSlotGetFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const createLeave = (id, startDate, endDate) => async (dispatch) => {
  try {
    console.log(id,startDate, endDate);
    const tempstartDate = startDate.split("/");
    const startDateModified =tempstartDate[2] + "-" + tempstartDate[1] + "-" + tempstartDate[0];
    startDateModified.trim();
    console.log(startDateModified);
    const tempendDate = endDate.split("/");
    const endDateModified =tempendDate[2] + "-" + tempendDate[1] + "-" + tempendDate[0];
    endDateModified.trim();
    console.log(endDateModified);
    dispatch(createLeaveStart());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/docotr/createLeave/${startDateModified}/${endDateModified}`,
		  {id},
      config
    );
    console.log(data.data.docs);
    dispatch(createLeaveSuccess(data.data.docs));
  } catch (error) {
    dispatch(
      createLeaveFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const blockSlot = (id, blockedSlots, dateModified) => async (dispatch) => {
  try {
    console.log(id, blockedSlots, dateModified);
    const tempstartDate = dateModified.split("/");
    const startDateModified =tempstartDate[2] + "-" + tempstartDate[1] + "-" + tempstartDate[0];
    startDateModified.trim();
    console.log(startDateModified);
    dispatch(blockSlotStart());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/docotr/blockSlots/${startDateModified}`,
        { id,
		  blockedSlots 
		},
      config
    );
    console.log(data.data.docs);
    dispatch(blockSlotSuccess(data.data.docs));
  } catch (error) {
    dispatch(
      blockSlotFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const unBlockSlot =
  (id, unBlockedSlots, dateModified) => async (dispatch) => {
    try {
      console.log(id, unBlockedSlots, dateModified);
      const tempstartDate = dateModified.split("/");
      const startDateModified =
        tempstartDate[2] + "-" + tempstartDate[1] + "-" + tempstartDate[0];
      startDateModified.trim();
      console.log(startDateModified);
      dispatch(unBlockSlotStart());

      const token = localStorage.getItem("token-user");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/docotr/unBlockSlots/${startDateModified}`,
        { id, unBlockedSlots },
        config
      );
      console.log(data.data.docs);
      dispatch(unBlockSlotSuccess(data.data.docs));
    } catch (error) {
      dispatch(
        unBlockSlotFailure(
          error.response && error.response.data.data.message
            ? error.response.data.data.message
            : error.message
        )
      );
    }
  };
