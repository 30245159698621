/** @format */

import { createSlice } from '@reduxjs/toolkit';

const changeOnHold = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		changeOnHoldStart: (state) => {
			state.loading = true;
		},
		changeOnHoldSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success=true
		},
		changeOnHoldFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		changeOnHoldReset: (state, action) => {
			state.error = false;
			state.success=false
		},
	},
});

export const {
	changeOnHoldStart,
	changeOnHoldSuccess,
	changeOnHoldFailure,
	changeOnHoldReset,
} = changeOnHold.actions;
export default changeOnHold.reducer;
