import { createSlice } from "@reduxjs/toolkit";

const changeVisited = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    changeVisitedStart: (state) => {
      state.loading = true;
    },
    changeVisitedSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    changeVisitedFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeVisitedReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  changeVisitedStart,
  changeVisitedSuccess,
  changeVisitedFailure,
  changeVisitedReset,
} = changeVisited.actions;
export default changeVisited.reducer;
