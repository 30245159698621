/** @format */

import Message from '../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import Form from 'react-bootstrap/Form';

const EditPrescriptionScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  return (
    <>
      <h4 className="patient-name mt-3">Patient Name: Venkat Ganesh</h4>
      <button className="btn offset-1 back-button">
        <i className="fa fa-angle-left custom fa-2x"></i>
      </button>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-3 offset-md-1">DOB: 12-03-2001</div>
          <div className="col-md-3 offset-md-1">Age: 21</div>
          <div className="col-md-3 offset-md-1">Gender: Male</div>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="row col-md-3">
            <div className="col-md-7">Temperature: </div>
            <input type="text" className="form-control col-md-4" />
          </div>
          <div className="row col-md-4">
            <div className="col-md-6">Blood Pressure: </div>
            <input type="text" className="form-control col-md-3" />
          </div>
          <div className="row col-md-3">
            <div className="col-md-5">Weight: </div>
            <input type="text" className="form-control col-md-4" />
          </div>
          <div className="row col-md-3">
            <div className="col-md-5">Height: </div>
            <input type="text" className="form-control col-md-4" />
          </div>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-2">Title:</div>
          <textarea className="col-md-7" rows={3}></textarea>
        </div>
        <div className="row mt-4 mb-2 section">
          <div className="col-md-2">Summary:</div>
          <textarea className="col-md-9" rows={6}></textarea>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">Medication</div>
        </div>
        <div className="row mt-4 mb-2 section">
          <DropdownButton
            className="col-md-3 btn"
            key="down-centered"
            id={`dropdown-button-drop-down-centered`}
            drop="down-centered"
            variant="success"
            title="Suggested Medicines"
            // onSelect={handleUserSelect}
          >
            <div className="dropButton">
              <input
                type="text"
                className="form-control"
                placeholder="Search Medicine"
              />
              <Dropdown.Item>Dolo 650MG</Dropdown.Item>
              <Dropdown.Item>Sinarest</Dropdown.Item>
              <Dropdown.Item>Ascoril</Dropdown.Item>
              <Dropdown.Item>DOLO 650MG</Dropdown.Item>
              <Dropdown.Item>Sotret 10MG</Dropdown.Item>
              <Dropdown.Item>Sotret 20MG</Dropdown.Item>
              <Dropdown.Item>Uprise D3 60k</Dropdown.Item>
            </div>
          </DropdownButton>
          <DropdownButton
            className="col-md-3 btn offset-md-1"
            key="down-centered"
            id={`dropdown-button-drop-down-centered`}
            drop="down-centered"
            variant="success"
            title="Templates"
            // onSelect={handleUserSelect}
          >
            <div className="dropButton">
              <input
                type="text"
                className="form-control"
                placeholder="Search Template"
              />
              <Dropdown.Item>Fever</Dropdown.Item>
              <Dropdown.Item>Cold</Dropdown.Item>
              <Dropdown.Item>Cough</Dropdown.Item>
              <Dropdown.Item>Hail Fall</Dropdown.Item>
              <Dropdown.Item>Acne Control</Dropdown.Item>
              <Dropdown.Item>Throat Infection</Dropdown.Item>
              <Dropdown.Item>Headache</Dropdown.Item>
            </div>
          </DropdownButton>
        </div>
        <div className="row mt-4 mb-2 section">
          <div className="col-md-3">
            <div className="col-md-6">Medicine:</div>
            <textarea type="form-control" rows={1} cols={33}>
              {" "}
            </textarea>
          </div>
          <div className="col-md-3 offset-md-1">
            <div className="col-md-6">Dosage:</div>
            <textarea type="form-control" rows={1} cols={33}>
              {" "}
            </textarea>
          </div>
          <div className="col-md-3 offset-md-1">
            <div className="col-md-6">Duration:</div>
            <textarea type="form-control" rows={1} cols={33}>
              {" "}
            </textarea>
          </div>
          <div className="col-md-1">
            <button className="m-2 btn btn-danger">
              <i className="fa fa-trash fa-2x"></i>
            </button>
          </div>
        </div>
        <div className="row mt-4 mb-2 section">
          <button className="btn btn-secondary offset-md-5">
            + Add more medicines
          </button>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-2">Advice:</div>
          <textarea className="col-md-9" rows={6}></textarea>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">Lab Tests</div>
        </div>
        <div className="row mt-4 mb-2 section">
          <div className="col-md-3">
            <div className="col-md-6">Test:</div>
            <textarea className="form-control col-md-12" rows={3}></textarea>
          </div>
          <div className="col-md-7 offset-md-1">
            <div className="col-md-3">Description:</div>
            <textarea className="form-control col-md-10" rows={3}></textarea>
          </div>
          <div className="col-md-1">
            <button className="m-2 btn btn-danger">
              <i className="fa fa-trash fa-2x"></i>
            </button>
          </div>
        </div>
        <div className="row mt-4 mb-2 section">
          <button className="btn btn-secondary offset-md-5">
            + Add more tests
          </button>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">Visit Next Doctor</div>
        </div>
        <div className="row mt-4 mb-2 section">
          <div className="col-md-3">
            <div className="col-md-6">Speciality:</div>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title="Choose Speciality"
              // onSelect={handleUserSelect}
            >
              <div className="dropButton">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Speciality"
                />
                <Dropdown.Item>Radiologist</Dropdown.Item>
                <Dropdown.Item>Cardiologist</Dropdown.Item>
                <Dropdown.Item>Gynaecologist</Dropdown.Item>
              </div>
            </DropdownButton>
          </div>
          <div className="col-md-3">
            <div className="col-md-6">Doctor:</div>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title="Chosse Doctor"
              // onSelect={handleUserSelect}
            >
              <div className="dropButton">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Doctor"
                />
                <Dropdown.Item>Dr Bhuvan Sai</Dropdown.Item>
                <Dropdown.Item>Dr Uday Kiran</Dropdown.Item>
                <Dropdown.Item>Dr Laya</Dropdown.Item>
              </div>
            </DropdownButton>
          </div>
          <div className="col-md-3">
            <div className="col-md-6">Date:</div>
            <input type="date" className="form-control" />
          </div>
          <div className="col-md-3">
            <div className="col-md-6">Slot:</div>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title="Chosse Slot"
              // onSelect={handleUserSelect}
            >
              <div className="dropButton">
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item className="bg-danger text-light">
                  9:30-10:30
                </Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
                <Dropdown.Item>9:30-10:30</Dropdown.Item>
              </div>
            </DropdownButton>
          </div>
        </div>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">Schedule Next Appointment</div>
        </div>
        <div className="row mt-4 mb-2 section">
          <div className="col-md-3 offset-md-4">
            <div className="col-md-6">Date:</div>
            <input type="date" className="form-control" />
          </div>
        </div>
      </Container>
      <Container className="mt-4">
        <div className="row mt-4 mb-2 offset-md-10 section">
          <button className="btn btn-success">Submit</button>
          <button className="offset-md-1 btn btn-danger">Clear</button>
        </div>
      </Container>
    </>
  );
};

export default EditPrescriptionScreen;
