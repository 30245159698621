/** @format */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { createSpeciality } from '../../redux/adminRedux/adminApiCalls';
import Message from '../../Components/Message';
import { createSpecialityReset } from '../../redux/adminRedux/createSpeciality';
import Form from 'react-bootstrap/Form';

const AddSpecialityScreens = () => {
	const dispatch = useDispatch();
	const [speciality, setSpeciality] = useState('');
	const [timerRunning, setTimerRunning] = useState(false);


  
  
	const submitHandler = (e) => {	
		e.preventDefault();
		console.log(speciality);
		var letters = /^[A-Za-z\s]+$/;
    	if (speciality == "") {
      		alert("Speciality cannot be empty");
    	}
		else if(!speciality.match(letters)){
      		alert("Speciality must contain only alphabets");
    	}
		else{
			let temp = window.confirm("Are you sure you want to create "+speciality);
			if (temp) {
				e.preventDefault();
				dispatch(createSpeciality(speciality));
				
				setSpeciality("");
			}	
		}
	};

	
	const [showPopup, setShowPopup] = React.useState(false);
	const createdSpeciality = useSelector((state) => state.createSpeciality);
	const { loading, success, userInfo, error } = createdSpeciality;
	const [ successfeedback,setsuccessfeedback]=useState(false)
	useEffect(() => {
		// If there's an alert message and the timer isn't running, start the timer
		if ((success) && !timerRunning) {
		  setTimerRunning(true);
		  const timer = setTimeout(() => {
			// Clear the alert and stop the timer after 3 seconds
			dispatch(createSpecialityReset());
			
			setTimerRunning(false);
		  }, 3000);
	
		  // Clean up the timer if the component unmounts or the alert changes
		  
		}
	  }, [success, dispatch, timerRunning]);
	// useEffect(() => {
	// 	setsuccessfeedback(true)
	// 	const abcd=() =>{
	// 		setsuccessfeedback(false)
	// 		dispatch(createSpecialityReset())
	// 	}
	// 	setTimeout(abcd(), 5000);
	// },[success,error])
	// useEffect(() => {
	// 	setTimerRunning(true);
	// 	const timer = setTimeout(() => {
	// 		document.getElementById('hi').innerHTML= "Hello"
	// 	}, 3000);
	// },[timerRunning])
	console.log(error)
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(createSpecialityReset());
	};
	return (
		<>
			<Meta />
			{error && (
				<Message variant='danger'>
					{/* <button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button> */}
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{success && (
				<Message variant='success'>
					{/* <button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button> */}
					{'Succesfully created'}
				</Message>
			)}
			<h1 className='offset-2 offset-md-5'>Add Speciality</h1>
			<Form
				className='form-group row mt-5'
				onSubmit={submitHandler}
				noValidate='true'>
				<label className='offset-md-2 offset-1 col-2 col-md-2' htmlFor='name'>
					<b>Name </b>
					<span className='text-danger'>
						<b>*</b>
					</span>
				</label>
				<input
					className='form-control col-8 col-md-6'
					id='speciality'
					type='text'
					placeholder={speciality}
					required='required'
					value={speciality}
					onChange={(e) => setSpeciality(e.target.value)}
				/>
				<button className='mt-3 offset-5 btn btn-success'>Submit</button>
			</Form>
			{/* <div id='hi'>Hi</div> */}
		</>
	);
};

export default AddSpecialityScreens;
