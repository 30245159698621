/** @format */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import {
	createDoctor,
	getSpecialities,
} from '../../redux/adminRedux/adminApiCalls';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Message from '../../Components/Message';
import { createDoctorReset } from '../../redux/adminRedux/createDoctor';

const AddDoctorScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	// console.log(getSpecialityAll);
	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	// console.log(allSpecialities.data.data)
	const [name, setName] = useState('');
	const [mobilenumber, setMobileNumber] = useState('');
	const [speciality, setSpeciality] = useState('Choose Speciality');
	const [maxCount, setMaxCount] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [startBreakTime, setStartBreakTime] = useState('');
	const [endBreakTime, setEndBreakTime] = useState('');
	const handleSpecialitySelect = (eventKey) => {
		setSpeciality(eventKey);
	};
	const submitHandler = (e) => {
		// console.log(speciality)
		e.preventDefault();
		var letters = /^[A-Za-z\s]+$/;
		if (name === '') {
			alert('Username cannot be empty');
		} else if (!name.match(letters)) {
			alert('Username must contain only alphabets');
		} else if (speciality === "Choose Speciality") {
      alert("Speciality needs to be selected first, Please select Speciality");
    } else if (maxCount == "" || maxCount <= 0) {
      alert("Maxcount cannot be less than or equal to zero");
    } else if (startTime === "") {
      alert("Start time can't be empty, Please enter start time");
    } else if (endTime === "") {
      alert("End time can't be empty, Please enter end time");
    } else if (startBreakTime == "") {
      alert("Break start time can't be empty, Please enter break start time");
    } else if (endBreakTime == "") {
      alert("Break end time can't be empty, Please enter break end time");
    } else if (startTime >= endTime) {
      alert(
        "End time can't be less than start time, Please enter correct end time"
      );
    } else if (startBreakTime >= endBreakTime) {
      alert(
        "Break end time can't be less than break start time, Please enter correct break end time"
      );
    } else {
      const startTimeFormat = startTime.split(":").join(".");
      const endTimeFormat = endTime.split(":").join(".");
      const StartBreakTimeFormat = startBreakTime.split(":").join(".");
      const EndBreakTimeFormat = endBreakTime.split(":").join(".");
      console.log(endTime.split(":").join("."));
      let breakTime = StartBreakTimeFormat + "-" + EndBreakTimeFormat;
      let temp = window.confirm(
        "Are you sure you want to create \n" +
          "name : " +
          name +
          "\n" +
          "mobilenumber : " +
          mobilenumber +
          "\n" +
          "speciality : " +
          speciality +
          "\n" +
          "maxCount : " +
          maxCount +
          "\n" +
          "startTime : " +
          startTimeFormat +
          "\n" +
          "endTime : " +
          endTimeFormat +
          "\n" +
          "breakTime : " +
          breakTime
      );
      if (!temp) {
        alert("Please enter correct Doctor details");
      } else {
        dispatch(
          createDoctor(
            name,
            mobilenumber,
            speciality,
            maxCount,
            startTimeFormat,
            endTimeFormat,
            breakTime
          )
        );
        setName("");
        setMobileNumber("");
        setSpeciality("Choose Speciality");
        setMaxCount("");
        setStartTime("");
        setEndTime("");
        setStartBreakTime("");
        setEndBreakTime("");
      }
    }
		
	};
	const createDoctor1 = useSelector((state) => state.createDoctor);
	console.log(createDoctor1);
	const { loading, error, userInfo, success } = createDoctor1;
	console.log(userInfo);
	useEffect(() => {
		dispatch(getSpecialities());
	}, [ userInfo, dispatch]);
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(createDoctorReset());
	};
	return (
		<>
			<Meta />
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup1}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{success && (
				<Message variant='success'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup1}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{'Succesfully created the doctor'}
				</Message>
			)}
			<h1 className='offset-md-5 offset-2'>Add Doctor </h1>
			<form className='mt-5' onSubmit={submitHandler}>
				<div className='row col-md-6 offset-md-3 form-group'>
					<label className='form-label col-md-3' htmlFor='name'>
						<b>Name: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<input
						className='form-control col-md-8'
						id='name'
						type='text'
						placeholder='Enter your name'
						required='required'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className='row col-md-6 offset-md-3 form-group'>
					<label className='form-label col-md-3' htmlFor='mobilenumber'>
						<b>Mobile Number: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<input
						className='form-control col-md-8'
						id='mobilenumber'
						type='text'
						placeholder='Enter your mobile number'
						required='required'
						value={mobilenumber}
						onChange={(e) => setMobileNumber(e.target.value)}
						minlength='10'
						maxlength='10'
						pattern='^[0-9]*$'
					/>
				</div>
				<div className='row col-md-6 offset-md-3 form-group row'>
					<label className='form-label col-md-3' htmlFor='speciality'>
						<b>Speciality: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<DropdownButton
						className='col-md-4'
						key='down-centered'
						id={`dropdown-button-drop-down-centered`}
						drop='down-centered'
						variant='secondary'
						title={speciality}
						onSelect={handleSpecialitySelect}>
						{allSpecialities.map((specialities, index) => (
							<Dropdown.Item eventKey={specialities.specialization}>
								{specialities.specialization}
							</Dropdown.Item>
						))}
					</DropdownButton>
				</div>
				<div className='row col-md-6 offset-md-3 form-group'>
					<label className='form-label col-md-3' htmlFor='maxCount'>
						<b>Max count: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<input
						className='form-control col-md-8'
						id='maxCount'
						type='number'
						placeholder='Enter max count'
						required='required'
						value={maxCount}
						onChange={(e) => setMaxCount(e.target.value)}
					/>
				</div>
				<div className='row col-md-6 offset-md-3 form-group'>
					<label className='form-label col-md-3' htmlFor='startTime'>
						<b>Start time: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					{/* <input
						className='form-control col-md-8'
						id='startTime'
						type='text'
						placeholder='Enter start time(example 9.30)'
						required='required'
						value={startTime}
						onChange={(e) => setStartTime(e.target.value)}
						// pattern='/^[+-]?\d+(\.\d+)?$/'
					/> */}
					<input
						className='form-control col-md-8'
						type='time'
						id='appt'
						name='appt'
						value={startTime}
						onChange={(e) => setStartTime(e.target.value)}
					/>
				</div>
				<div className='row col-md-6 offset-md-3 form-group'>
					<label className='form-label col-md-3' htmlFor='endTime'>
						<b>End time: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					{/* <input
						className='form-control col-md-8'
						id='endTime'
						type='text'
						placeholder='Enter end time(example 8.30)'
						required='required'
						value={endTime}
						onChange={(e) => setEndTime(e.target.value)}
						// pattern='/^[+-]?\d+(\.\d+)?$/'
					/> */}
					<input
						className='form-control col-md-8'
						type='time'
						id='appt'
						name='appt'
						value={endTime}
						onChange={(e) => setEndTime(e.target.value)}
					/>
				</div>
				<div className='row col-md-6 offset-md-3 form-group'>
					<label className='form-label col-md-3' htmlFor='breakTime'>
						<b>Break time: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					{/* <input
						className='form-control col-md-3'
						id='breakTime'
						type='text'
						placeholder='12.30'
						required='required'
						value={startBreakTime}
						onChange={(e) => setStartBreakTime(e.target.value)}
					/> */}
					<input
						className='form-control col-md-3'
						type='time'
						id='appt'
						name='appt'
						value={startBreakTime}
						onChange={(e) => setStartBreakTime(e.target.value)}
					/>
					{/* <input
						className='form-control col-md-3 offset-md-1'
						id='breakTime'
						type='text'
						placeholder='13.30'
						required='required'
						value={endBreakTime}
						onChange={(e) => setEndBreakTime(e.target.value)}
					/> */}
					<input
						className='form-control col-md-3 offset-md-1'
						type='time'
						id='appt'
						name='appt'
						value={endBreakTime}
						onChange={(e) => setEndBreakTime(e.target.value)}
					/>
				</div>
				<div className='col-md-6 offset-md-6 offset-4 form-group'>
					<button className='btn btn-success mt-3'>Submit</button>
				</div>
			</form>
		</>
	);
};

export default AddDoctorScreens;
