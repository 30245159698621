/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Errortemplate from '../Components/Errortemplate';
// import { Col, Row } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Loader from '../Components/Loader';
import Meta from '../Components/Meta';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import {
	updateUserProfile,
	getSpecialities,
	getDoctorFromSpeciality,
	getTimeslots,
	createBooking,
} from '../redux/userApiCalls';
// import {bookingListScreen} from "../redux/userApiCalls";
import { userUpdateProfileReset } from '../redux/userUpdate';
import { createBookingReset } from '../redux/createBooking';
import Message from '../Components/Message';
import {
	getUserDataByCategoryForUser,
} from '../redux/adminRedux/adminApiCalls';

import { getUserDataByCategoryReset } from '../redux/adminRedux/getUserDataByCategory';

const BookingListScreen = () => {
	const loading = 0;
	const dispatch = useDispatch();
	const history = useNavigate();
	const [lgShow, setLgShow] = useState(false);
	const [name, setName] = useState('');
	const [gender, setGender] = useState('');
	const [dob, setdob] = useState(new Date());
	//const [mno, setMno] = useState('');
	const data2 = useSelector((state) => state.userLogin);
	const mno=data2?.userInfo?.data?.user?.mobilenumber
	
	useEffect(() => {
		dispatch(getUserDataByCategoryForUser(mno));
		// if(mno !== "")
		// dispatch(getUserDataByCategory(mno));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [mno]);
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	const userdataerror = data1.error;
	const userdataloading=data1.loading
	const userdatasuccess=data1.success
	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + ' ' + gender + ' ' + dob);
		var flag=0
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			if(userData?.data?.data?.registered_patients_array[i]?.name === name){
				flag=1
				break
			}
			
		}
		var letters = /^[A-Za-z\s]+$/;
		if (name === '') {
			alert('Username cannot be empty');
		} else if (!name.match(letters)) {
			alert('Username must contain only alphabets');
		} else if (gender === '') {
			alert('Please select gender, gender cannot be empty');
		} else if (dob === '') {
			alert('Please select dob, dob cannot be empty');
		} 
		else if (new Date(dob) >=	new Date(new Date())) {
			alert('Please select proper dob, dob should not exceed todays date');
		} 
		else if (name === userData?.data?.data?.name || flag==1){
			alert('Duplicate names are not allowed');
		}
		else {
			dispatch(updateUserProfile(name, gender, dob));
			setName('');
			setGender('');
			setdob('');
      setLgShow(false)
		}
	};
	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	let allSpecialities = [];
	let allDoctors = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}

	console.log(allSpecialities);
	const { getDoctorfromSpeciality } = useSelector((state) => state.getDoctor);
	if (getDoctorfromSpeciality) {
		if (getDoctorfromSpeciality.data[0]) {
			console.log(getDoctorfromSpeciality.data);
			if (getDoctorfromSpeciality.data[0].doctorsArray) {
				for (
					let i = 0;
					i < getDoctorfromSpeciality.data[0].doctorsArray.length;
					i++
				) {
					allDoctors.push(getDoctorfromSpeciality.data[0].doctorsArray[i][0]);
				}
			}
		}
	}
	console.log(allDoctors);
	const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const bookingallinfo = useSelector((state) => state.createBooking);
	const bookingerror = bookingallinfo.error;
	const createdbookingloading = bookingallinfo.loading;
	const bookinguserInfo = bookingallinfo.userInfo;
	const bookingsuccess = bookingallinfo.success;

	// console.log(getDoctorfromSpeciality?.data?.data[0]?.doctorsArray[1][0]?.name)
	let doctors = [];
	const week = [
		'sunday',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
	];
	const days = [];
	const currDate = new Date(); // Get current date
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(
      currDate.getFullYear(),
      currDate.getMonth(),
      currDate.getDate() + i
    );
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month index starts from 0
    const year = currentDate.getFullYear();
    const formattedDate = date + " / " + month + " / " + year;
    days.push(formattedDate);
  }
	const { userInfo } = useSelector((state) => state.userLogin);

	useEffect(() => {
		dispatch(getSpecialities());
	}, [history, userInfo, dispatch]);
	const users_array = [];
	users_array.push({
		name: userData?.data?.data?.name,
		id: userData?.data?.data?.bookingId,
	});
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		users_array.push({
			name: userData?.data?.data?.registered_patients_array[i]?.name,
			id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
		});
	}
	console.log(users_array);
	const [userOption, setUserOption] = useState('Select a user');

	const [specialityOption, setSpecialityOption] = useState('Choose Specialty');
	const [specialityId, setSpecialityId] = useState('');
	const handleSpecialitySelect = (eventKey) => {
		if (userOption === 'Select a user') {
			alert('User needs to be selected first, Please select user');
		} else {
			let arr = [];
			arr = eventKey.split('/');
			setSpecialityOption(arr[0]);
			setSpecialityId(arr[1]);
			setDoctorOption('Choose Doctor');
			setDateOption('Choose Date');
			setDateSelected(false);
			setSlotOption();
			setSlotCount(0);
			dispatch(getDoctorFromSpeciality(arr[0]));
		}
	};
	const [doctorOption, setDoctorOption] = useState('Choose Doctor');
	const [doctorId, setDoctorId] = useState('');
	const handleDoctorSelect = (eventKey) => {
		if (userOption == 'Select a user') {
			alert('User needs to be selected first, Please select user');
		} else if (specialityOption == 'Choose Specialty') {
			alert('Speciality needs to be selected first, Please select Speciality');
		} else {
			let arr = [];
			arr = eventKey.split('/');
			setDoctorId(arr[1]);
			setDoctorOption(arr[0]);
			setDateOption('Choose Date');
			setDateSelected(false);
			setSlotOption();
			setSlotCount(0);
		}
	};
	const [dateOption, setDateOption] = useState('Choose Date');
	const [dateSelected, setDateSelected] = useState(false);
	const handleDateSelect = (eventKey) => {
		if (userOption == 'Select a user') {
			alert('User needs to be selected first, Please select user');
		} else if (specialityOption == 'Choose Specialty') {
			alert('Speciality needs to be selected first, Please select Speciality');
		} else if (specialityOption == 'Choose Specialty') {
			alert('Speciality needs to be selected first, Please select Speciality');
		} else if (doctorOption == 'Choose Doctor') {
			alert('Doctor needs to be selected first, Please select Doctor');
		} else {
			setDateOption(eventKey);
			setDateSelected(true);
			dispatch(getTimeslots(doctorOption, specialityOption, eventKey));
		}
	};
	const [slotOption, setSlotOption] = useState();
	const [slotCount, setSlotCount] = useState(0);
	
	const handleSlotSelect = (slot, slotcount) => {
		setSlotOption(slot);
		setSlotCount(slotcount + 1);
	};
	const [isChecked, setIsChecked] = useState(false);
	let flag = false;
	const [textVis, setTextVis] = useState('d-none offset-md-3 mt-3 text-danger');
	const handleUserSelect = (eventKey) => {
		setTextVis('d-none offset-md-3 mt-3 text-danger');
		setUserOption(eventKey);
		flag = false;
		for (let i = 0; i < users_array.length; i++) {
			if (eventKey === users_array[i].name && users_array[i].id !== '') {
				flag = true;
				break;
			}
		}
		if (flag) {
			setTextVis('d-block offset-md-3 mt-3 text-danger');
		}
		setSpecialityOption('Choose Specialty');
		setSpecialityId('');
		setDoctorOption('Choose Doctor');
		setDateOption('Choose Date');
		setDateSelected(false);
		setSlotOption();
		setSlotCount(0);
	};
	const submitHandler = async () => {
		if (userOption == 'Select a user') {
			alert('User needs to be selected first, Please select user');
		} else if (specialityOption == 'Choose Specialty') {
			alert('Speciality needs to be selected first, Please select Speciality');
		} else if (doctorOption == 'Choose Doctor') {
			alert('Doctor needs to be selected first, Please select Doctor');
		} else if (dateOption == 'Choose Date') {
			alert('Date needs to be selected first, Please select Date');
		} else if (slotOption == undefined) {
			alert('Please select a vaild slot');
		} else if (isChecked == false) {
			alert('Please tick the check box');
		} else {
			const tempArr = dateOption.split('/');
			const dateModified = tempArr[2] + '/' + tempArr[1] + '/' + tempArr[0];
			const dt = new Date(dateModified);
			var day = dt.getDay();
			console.log(week[day], dateModified, dt, day);
			dispatch(
				createBooking(
					userOption,
					doctorOption,
					specialityOption,
					specialityId,
					doctorId,
					dateModified,
					slotOption,
					slotCount,
					week[day],
					userInfo.data.user.mobilenumber
				)
			);
			setUserOption('Select a user');
			setSpecialityOption('Choose Specialty');
			setDoctorOption('Choose Doctor');
			setDateOption('Choose Date');
			setSpecialityId('');
			setDoctorId('');
			setDateSelected(false);
			setSlotOption();
			setSlotCount(0);
			setIsChecked(false);
			document.getElementById('default-checkbox').checked = false;
		}
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(userUpdateProfileReset());
	};
	const [showPopup2, setShowPopup2] = React.useState(false);

	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log('error');
		dispatch(createBookingReset());
	};
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(getUserDataByCategoryReset());
	};
  const [timeSlotsArr, setTimeSlotsArr] = useState([]);
  useEffect(() => {
    	var timeSlots = [];
      var isEmpty;
		for (let i = 0; i < allTimeSlots?.length; i++) {
			isEmpty = allTimeSlots[i].isEmpty;
			timeSlots.push({
				isEmpty: isEmpty,
				timeSlot: allTimeSlots[i].timeSlot,
				curCount: allTimeSlots[i].curCount,
				maxCount: allTimeSlots[i].maxCount, //10:30-11:15 =6 if maxcount=8
			});
		}	
    setTimeSlotsArr(timeSlots);
    console.log(timeSlotsArr);
	}, [allTimeSlots, history, dispatch]);
	useEffect(() => {
		//dispatch(getSpecialities());
		if (mno !== '') dispatch(getUserDataByCategoryForUser(mno));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [history, dispatch, adduserinfosuccess, adduserinfoerror,bookingsuccess,bookingerror]);
	return (
    <>
      <Meta />
      {userdataerror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {userdataerror}
        </Message>
      )}
      {userdataloading && <Loader />}
      {bookingerror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup2}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {adduserinfoerror}
        </Message>
      )}
      {adduserinfoerror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {adduserinfoerror}
        </Message>
      )}
      {createdbookingloading && <Loader />}
      {bookingsuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup2}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Booking successfully created"}
        </Message>
      )}
      {adduserinfosuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Added new user"}
        </Message>
      )}
      <div className="container">
        <h1 className="offset-md-4">Book Appointment</h1>
        <div className="card">
          <div className="mb-2 mt-5 row">
            <h6 className="col-md-3 offset-md-1">
              Select a user{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={userOption}
              onSelect={handleUserSelect}
            >
              {users_array.map((users, index) => (
                <Dropdown.Item eventKey={users.name}>
                  {users.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            {users_array.length >= 6 ? (
              <Button
                disabled
                className="btn offset-4 offset-md-0 btn-danger"
                onClick={() => setLgShow(true)}
              >
                Users limit reached
              </Button>
            ) : (
              <Button
                className="btn offset-4 offset-md-0 btn-secondary"
                onClick={() => setLgShow(true)}
              >
                Add User
              </Button>
            )}
            <div id="userErrMsg" className={textVis}>
              <b>
                Selected user already has an appointment. Please select another
                user.
              </b>
            </div>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Add New User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="form-group" onSubmit={addUserOnSubmit}>
                  <div className="row mt-3">
                    <label className="col-3 col-md-3" htmlFor="name">
                      <b>Name </b>{" "}
                      <span className="text-danger">
                        <b>*</b>
                      </span>
                    </label>
                    <input
                      className="form-control col-8 col-md-8"
                      id="name"
                      type="name"
                      required="required"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="row mt-3">
                    <label htmlFor="gender" className="col-4 col-md-3">
                      <b>Gender</b>{" "}
                      <span className="text-danger">
                        <b>*</b>
                      </span>
                    </label>
                    <div className="row">
                      <b>
                        <Form.Check
                          onChange={() => {
                            setGender("Male");
                          }}
                          checked={gender === "Male"}
                          className="form-check col-5 col-md-4"
                          inline
                          label="Male"
                          type="radio"
                          id={`inline-radio-1`}
                        />
                        <Form.Check
                          onChange={() => {
                            setGender("Female");
                          }}
                          checked={gender === "Female"}
                          inline
                          label="Female"
                          className="form-check col-5 col-md-4"
                          type="radio"
                          id={`inline-radio-2`}
                        />
                      </b>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <label htmlFor="date" className="col-md-3">
                      <b>Date of Birth</b>{" "}
                      <span className="text-danger">
                        <b>*</b>
                      </span>
                    </label>
                    <input
                      type="date"
                      className="col-md-4 form-control"
                      selected={dob}
                      value={dob}
                      onChange={(e) => setdob(e.target.value)}
                    />
                  </div>
                  <div>
                    <Button
                      className="offset-5 offset-md-6 mt-5 btn"
                      type="submit"
                    >
                      Add
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <div className="card mt-5">
          <div className="row mt-5">
            <h6 className="col-md-3 offset-md-1">
              Select a doctor and speciality{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={specialityOption}
              onSelect={handleSpecialitySelect}
            >
              {allSpecialities.map((specialities, index) => (
                <Dropdown.Item
                  eventKey={
                    specialities.specialization + "/" + specialities._id
                  }
                >
                  {specialities.specialization}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={doctorOption}
              onSelect={handleDoctorSelect}
            >
              {allDoctors.map((doctors, index) => (
                <Dropdown.Item eventKey={doctors.name + "/" + doctors._id}>
                  {doctors.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="row mt-5">
            <h6 className="col-md-3 offset-md-1">
              Select a date{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
            <DropdownButton
              className="col-md-2 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={dateOption}
              onSelect={handleDateSelect}
            >
              {days.map((day, index) => (
                <Dropdown.Item eventKey={day}>{day}</Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="row">
            <h6 className="col-md-2 offset-md-1">
              Select a slot{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
          </div>

          <div className="row">
            <div className="offset-1 ">
              {dateSelected &&
                timeSlotsArr.map((timeslots, index) =>
                  timeslots.isEmpty ? (
                    // <button
                    // 	onClick={() =>
                    // 		handleSlotSelect(timeslots.timeSlot, timeslots.curCount)
                    // 	}
                    // 	className='slotButton slotButton-success col-md-3 m-1' autofocus>

                    // 	{timeslots.timeSlot}
                    // 	<br /> Slot no: {timeslots.curCount} <br />
                    // </button>

                    <Form.Check
                      onChange={() =>
                        handleSlotSelect(timeslots.timeSlot, timeslots.curCount)
                      }
                      className="btn btn-success col-md-3 m-1 col-10"
                      type="radio"
                      id={timeslots.timeSlot}
                      label={`${timeslots.timeSlot} available slots: ${
                        timeslots.maxCount - timeslots.curCount
                      } `}
                      checked={slotOption === timeslots.timeSlot}
                    />
                  ) : (
                    <Button
                      className="btn btn-danger col-md-3 m-1 col-10"
                      disabled
                    >
                      {timeslots.timeSlot} No slots available
                    </Button>
                  )
                )}
            </div>
          </div>

          {/* <Button className="col-2 me-1 btn-success"> 10:00 - 11:00</Button>
          <Button className="col-2 me-1 btn-success"> 11:00 - 12:00</Button>
          <Button className="col-2 me-1 btn-danger"> 12:00 - 1:00</Button>
          <Button className="offset-md-5 mt-2 col-2 me-1 btn-success">
            {" "}
            1:00 - 2:00
          </Button>
          <Button className="col-2 me-1 mt-2 btn-success"> 3:00 - 4:00</Button>
          <Button className="col-2 me-1 mt-2 btn-success"> 4:00 - 5:00</Button>
          <Button className="offset-md-5 mt-2 col-2 me-1 btn-danger">
            {" "}
            5:00 - 6:00
          </Button>
          <Button className="col-2 me-1 mt-2 btn-danger"> 6:00 - 7:00</Button>
          <Button className="col-2 me-1 mt-2 btn-success"> 7:00 - 8:00</Button> */}
          <div key={`default-checkbox`} className="offset-1 offset-md-4 mt-5">
            <Form.Check
              type="checkbox"
              id={`default-checkbox`}
              label={`I agree that the timing may change according to the doctor's schedule.`}
              onChange={() => setIsChecked(true)}
            />
          </div>
          {textVis === "d-block offset-md-3 mt-3 text-danger" ? (
            <Button
              disabled
              className="offset-2 col-8 col-md-2 offset-md-5 mt-2 btn-danger"
              onClick={submitHandler}
            >
              Book Appointment
            </Button>
          ) : (
            <Button
              className="offset-2 col-8 col-md-2 offset-md-5 mt-2 btn-secondary"
              onClick={submitHandler}
              disabled={!isChecked}
            >
              Book Appointment
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingListScreen;
