import { createSlice } from "@reduxjs/toolkit";

const UserReviewGetReducer = createSlice({
	name: "UserReviewGet",
	initialState: {},
	reducers: {
		UserReviewGetRequest: (state) => {
			state.loading = true;
		},
		UserReviewGetSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getReview = action.payload;
			state.error=false;
			state.success = true;
		},
		UserReviewGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		UserReviewGetReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	UserReviewGetRequest,
	UserReviewGetSuccess,
	UserReviewGetFailure,
	UserReviewGetReset,
} = UserReviewGetReducer.actions;
export default UserReviewGetReducer.reducer;
