/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../Components/Errortemplate";
import Loader from "../Components/Loader";
import { register } from "../redux/userApiCalls";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Message from "../Components/Message";
import { registerReset } from "../redux/userRegister";

const RegisterScreen = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [passwordConfirm, setpasswordConfirm] = useState("");
  const [dob, setdob] = useState("");
  const userLogin = useSelector((state) => state.userRegister);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show1, setShow1] = useState(false);
  const handleClick1 = () => setShow1(!show1);
  console.log(userLogin + "jdkjs");
  const { loading, error, userInfo, success } = useSelector(
    (state) => state.userRegister
  );
  useEffect(() => {
    if (userInfo) {
      console.log("111");
      localStorage.removeItem("userRegister");
      history("/login");
    }
  }, [history, userInfo]);
  const submitHandler = (e) => {
    e.preventDefault();
    const currDate = new Date(); // Get current date
    let year = currDate.getFullYear();
    let year_now = dob.split("-")[0];
    console.log(year, year_now);
    var letters = /^[A-Za-z\s]+$/;
    if (name == "") {
      alert("Username cannot be empty");
    } else if (!name.match(letters)) {
      alert("Username must contain only alphabets");
    } else if (gender == "") {
      alert("Please select gender, gender cannot be empty");
    } else if (dob == "") {
      alert("Please select dob, dob cannot be empty");
    } else if (year - year_now < 18) {
      alert("Insufficient age, You need to be atleast 18 years");
    } else if (password != passwordConfirm) {
      alert("Passwords did not match");
    } else {
      // alert("User created successfully");
      dispatch(register(name, password, mobilenumber, gender, dob));
    }
  };
  const [showPopup, setShowPopup] = React.useState(false);

  const toggleShowInfoPopup = (e) => {
    setShowPopup(!showPopup);
    e.preventDefault();
    console.log("error");
    dispatch(registerReset());
  };

  return (
    <>
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}
      {success && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Registered succesfully"}
        </Message>
      )}
      <div className="row row-content">
        <div className="col-12 col-md-4 offset-md-4 registerForm">
          <h2 className="loginHeader">Register</h2>
          <form className="form-group" id="user-form" onSubmit={submitHandler}>
            <div className="form-group">
              <label for="name" className="col-md-12 col-form-label">
                <b>Name:</b>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  required="required"
                  minLength="4"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="mobileno" className="col-md-12 col-form-label">
                <b>Mobile Number:</b>
              </label>
              <div className="col-md-12">
                <input
                  type="tel"
                  className="form-control"
                  id="mobileno"
                  name="mobileno"
                  placeholder="Enter Your Mobile Number"
                  required="required"
                  onChange={(e) => setmobilenumber(e.target.value)}
                  minlength="10"
                  maxlength="10"
                  pattern="^[0-9]*$"
                />
              </div>
            </div>
            <div className="form-group">
              <label for="mobileno" className="col-md-12 col-form-label">
                <b>Gender:</b>
              </label>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-6">
                    <Form.Check
                      onChange={() => {
                        setGender("Male");
                      }}
                      checked={gender === "Male"}
                      className="form-control"
                      inline
                      label="Male"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                  </div>
                  <div className="col-6">
                    <Form.Check
                      onChange={() => {
                        setGender("Female");
                      }}
                      checked={gender === "Female"}
                      inline
                      label="Female"
                      className="form-control"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label for="dob" className="col-md-12 col-form-label">
                <b>Date Of Birth:</b>
              </label>
              <div className="col-md-12">
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  onChange={(e) => setdob(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="password" className="col-md-12 col-form-label">
                <b>Password:</b>
              </label>
              <div className="col-md-12 flex321">
                <input
                  
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="••••••••"
                  required
                  minLength={8}
                  onChange={(e) => setPassword(e.target.value)}
                  type={show ? "text" : "password"}
                />
                <button type="button" className="close" onClick={handleClick}>
                  {show == 1 ? (
                    <i class="fa fa-eye ppppp" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-eye-slash ppppp" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="form-group">
              <label for="password1" className="col-md-12 col-form-label">
                <b>Confirm password:</b>
              </label>
              <div className="col-md-12 flex321">
                <input
                 
                  className="form-control"
                  id="password1"
                  name="password1"
                  placeholder="••••••••"
                  required
                  minLength={8}
                  onChange={(e) => setpasswordConfirm(e.target.value)}
				  type={show1 ? "text" : "password"}
                />
				<button type="button" className="close" onClick={handleClick1}>
                  {show1 == 1 ? (
                    <i class="fa fa-eye ppppp" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-eye-slash ppppp" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-success mt-5 offset-2 col-8"
              >
                <b>Register</b>
              </button>
              <h6 className='col-7 offset-3 mt-3'>Already created an account</h6>
						<a
							role='button'
							href={'/login'}
							className='btn offset-2 btn-success col-8'>
							Login
						</a>
            </div>
          </form>
        </div>
      </div>
      {/* <main className="main">
				<div className="login-form">
					<h2 className="heading-secondary ma-bt-lg">Register your account</h2>
					* {error && <Errortemplate msg={error}></Errortemplate>}
					{loading && <Loader />} *
					{!error && (
						<form className="form form-group" onSubmit={submitHandler}>
							<div className="form__group">
								<label className="form__label" htmlFor="name">
									Name
								</label>
								<input
									className="form__input"
									id="name"
									type="name"
									placeholder="you"
									required="required"
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="form__group">
								<label className="form__label" htmlFor="mobileno">
									Mobile number
								</label>
								<input
									className="form__input"
									id="mobileno"
									type="mobileno"
									placeholder="9999999999"
									required="required"
									onChange={(e) => setmobilenumber(e.target.value)}
								/>
							</div>
							<div className="form__group">
								<label htmlFor="gender" className="col-2 form__label">
									Gender
								</label>
								<div className="row">
									<Form.Check
										onChange={() => {
											setGender("Male");
										}}
										checked={gender === "Male"}
										className="form__input col-4"
										inline
										label="Male"
										type="radio"
										id={`inline-radio-1`}
									/>
									<Form.Check
										onChange={() => {
											setGender("Female");
										}}
										checked={gender === "Female"}
										inline
										label="Female"
										className="form__input col-4"
										type="radio"
										id={`inline-radio-2`}
									/>
								</div>
							</div>
							<div className="form__group">
								<label htmlFor="dob" className="form__label">
									Select Date of birth
								</label>
								<input
									type="date"
									selected={dob}
									className="form__input"
									onChange={(e) => setdob(e.target.value)}
								/>
							</div>
							<div className="form__group ma-bt-md">
								<label className="form__label" htmlFor="password">
									Password
								</label>
								<input
									className="form__input"
									id="password"
									type="password"
									placeholder="••••••••"
									required="required"
									minLength={8}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="form__group ma-bt-md">
								<label className="form__label" htmlFor="password1">
									Password Confirm
								</label>
								<input
									className="form__input"
									id="password1"
									type="password"
									placeholder="••••••••"
									required="required"
									minLength={8}
									onChange={(e) => setpasswordConfirm(e.target.value)}
								/>
							</div>
							<div>
								<Button type="submit" className="btn offset-md-5 btn-success">
									Register
								</Button>
							</div>
						</form>
					)}
				</div>
			</main> */}
    </>
  );
  // return (
  // 	<>
  // 		<main className="main">
  // 			<div className="login-form">
  // 				<h2 className="heading-secondary ma-bt-lg">Register your account</h2>
  // 				{error && <Errortemplate msg={error}></Errortemplate>}
  // 				{loading && <Loader />}
  // 				{!error && (
  // 					<form className="form form--login" onSubmit={submitHandler}>
  // 						<div className="form__group">
  // 							<label className="form__label" htmlFor="name">
  // 								Name
  // 							</label>
  // 							<input
  // 								className="form__input"
  // 								id="name"
  // 								type="name"
  // 								placeholder="you"
  // 								required="required"
  // 								onChange={(e) => setName(e.target.value)}
  // 							/>
  // 						</div>
  // 						<div className="form__group">
  // 							<label className="form__label" htmlFor="mobileno">
  // 								Mobile number
  // 							</label>
  // 							<input
  // 								className="form__input"
  // 								id="mobileno"
  // 								type="mobileno"
  // 								placeholder="9999999999"
  // 								required="required"
  // 								onChange={(e) => setmobilenumber(e.target.value)}
  // 							/>
  // 						</div>
  // 						<div className="form__group">
  // 							<label htmlFor="gender" className="col-2 form__label">
  // 								Gender
  // 							</label>
  // 							<div className="row">
  // 								<Form.Check
  // 									onChange={() => {
  // 										setGender("Male");
  // 									}}
  // 									checked={gender === "Male"}
  // 									className="form__input col-4"
  // 									inline
  // 									label="Male"
  // 									type="radio"
  // 									id={`inline-radio-1`}
  // 								/>
  // 								<Form.Check
  // 									onChange={() => {
  // 										setGender("Female");
  // 									}}
  // 									checked={gender === "Female"}
  // 									inline
  // 									label="Female"
  // 									className="form__input col-4"
  // 									type="radio"
  // 									id={`inline-radio-2`}
  // 								/>
  // 							</div>
  // 						</div>
  // 						<div className="form__group">
  // 							<label htmlFor="dob" className="form__label">
  // 								Select Date of birth
  // 							</label>
  // 							<input
  // 								type="date"
  // 								selected={dob}
  // 								className="form__input"
  // 								onChange={(e) => setdob(e.target.value)}
  // 							/>
  // 						</div>
  // 						<div className="form__group ma-bt-md">
  // 							<label className="form__label" htmlFor="password">
  // 								Password
  // 							</label>
  // 							<input
  // 								className="form__input"
  // 								id="password"
  // 								type="password"
  // 								placeholder="••••••••"
  // 								required="required"
  // 								minLength={8}
  // 								onChange={(e) => setPassword(e.target.value)}
  // 							/>
  // 						</div>
  // 						<div className="form__group ma-bt-md">
  // 							<label className="form__label" htmlFor="password1">
  // 								Password Confirm
  // 							</label>
  // 							<input
  // 								className="form__input"
  // 								id="password1"
  // 								type="password"
  // 								placeholder="••••••••"
  // 								required="required"
  // 								minLength={8}
  // 								onChange={(e) => setpasswordConfirm(e.target.value)}
  // 							/>
  // 						</div>
  // 						<div>
  // 							<Button type="submit" className="btn offset-md-5 btn-success">
  // 								Register
  // 							</Button>
  // 						</div>
  // 					</form>
  // 				)}
  // 			</div>
  // 		</main>
  // 	</>
  // );
};

export default RegisterScreen;
