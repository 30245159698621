/** @format */
import {
	getUserDataByCategoryRequest,
	getUserDataByCategorySuccess,
	getUserDataByCategoryFailure,
} from './getUserDataByCategory';

import {
	getUserDataInSearchPatientsRequest,
	getUserDataInSearchPatientsSuccess,
	getUserDataInSearchPatientsFailure,
} from './getUserDataInSearchPatients';
import {SERVER_URL} from "../../App"
import axios from 'axios';
import {
	createSpecialityStart,
	createSpecialitySuccess,
	createSpecialityFailure,
} from './createSpeciality';
import {
	createReviewStart,
	createReviewSuccess,
	createReviewFailure,
} from './createReview.js';
import {
	specialityGetRequest,
	specialityGetSuccess,
	specialityGetFailure,
	specialityGetReset,
} from './getSpeciality';
import {
	getReviewsGetRequest,
	getReviewsGetFailure,
	getReviewsGetSuccess,
	getReviewsGetReset,
} from './getReviews';
import { persistor } from '../store';
import {
	createDoctorStart,
	createDoctorSuccess,
	createDoctorFailure,
} from './createDoctor';

import {
	doctorBookingGetFailure,
	doctorBookingGetRequest,
	doctorBookingGetSuccess,
} from './getBooking';
import { loginStart, loginFailure, loginSuccess, logout } from './adminLogin';

import {
	changeVisitedStart,
	changeVisitedFailure,
	changeVisitedSuccess,
} from './changeVisited';
import {
	changeOnHoldStart,
	changeOnHoldFailure,
	changeOnHoldSuccess,
} from './changeOnHold';
import {
	changeSlotSuccess,
	changeSlotFailure,
	changeSlotStart,
} from './changeSlot';

import {
	adminCreateBookingStart,
	adminCreateBookingFailure,
	adminCreateBookingSuccess,
} from './adminCreateBooking';

export const login = (username, password) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(loginStart());

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/admin/login`,
			{ username, password },
			config
		);
		console.log(data);
		localStorage.setItem('token-user', data.token);
		// localStorage.setItem("name", data.user.name);
		localStorage.setItem('adminInfo', JSON.stringify(data));

		// console.log(data.user);
		dispatch(loginSuccess(data));
	} catch (error) {
		dispatch(
			loginFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const logout1 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem('userInfo');
	localStorage.removeItem('adminInfo');
	localStorage.removeItem('token-user');
	localStorage.removeItem('persist:root');
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const createSpeciality = (speciality) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(createSpecialityStart());
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/speciality/create`,
			{ speciality },
			config
		);

		// console.log(data.user);
		dispatch(createSpecialitySuccess(data));
	} catch (error) {
		console.log(error)
		dispatch(
			createSpecialityFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getSpecialities = () => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(specialityGetRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/speciality/getAll`,

			config
		);
		console.log(data);
		dispatch(specialityGetSuccess(data));
	} catch (error) {
		dispatch(
			specialityGetFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const createReview = (push, id) => async (dispatch, getState) => {
	try {
		dispatch(createReviewStart());

		const {
			userLogin: { userInfo },
		} = getState();
		console.log(getState());
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};

		const data = await axios.post(
			`${SERVER_URL}/api/v1/admin/push`,
			{ push: push, id: id },
			config
		);
		console.log(data + 'review123');
		dispatch(createReviewSuccess(data));
	} catch (error) {
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === 'Not authorized, token failed') {
			localStorage.removeItem('persist:root');
			dispatch(logout());
		}
		dispatch(createReviewFailure(message));
	}
};

export const getReviews = () => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getReviewsGetRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getAllReviews`,

			config
		);
		console.log(data);
		dispatch(getReviewsGetSuccess(data));
	} catch (error) {
		dispatch(
			getReviewsGetFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const createDoctor =
	(name, mobilenumber, speciality, maxCount, startTime, endTime, breakTime) =>
	async (dispatch) => {
		try {
			console.log(breakTime);
			dispatch(createDoctorStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/adddoctor`,
				{
					name,
					mobilenumber,
					speciality,
					maxCount,
					startTime,
					endTime,
					breakTime,
				},
				config
			);

			console.log(data);
			dispatch(createDoctorSuccess(data));
		} catch (error) {
			console.log(error)
			dispatch(
				createDoctorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getDoctorsAppointmentsForDate =
	(speciality, doctor, date) => async (dispatch) => {
		try {
			console.log(speciality + ' ' + doctor + '  ' + date);
			dispatch(doctorBookingGetRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			console.log(config.headers.Authorization + '1111222233333');
			console.log(
				`${SERVER_URL}/api/v1/booking/getDoctorsAppointmentsForDate/${speciality}/${doctor}/${date}`
			);
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/booking/getDoctorsAppointmentsForDate/${speciality}/${doctor}/${date}`,
				config
			);

			console.log(data);
			dispatch(doctorBookingGetSuccess(data));
		} catch (error) {
			dispatch(
				doctorBookingGetFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const changeToVisited = (id) => async (dispatch) => {
	try {
		console.log('11223344' + id);
		dispatch(changeVisitedStart());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/changeVisited`,
			{ id },
			config
		);
		console.log(data);
		dispatch(changeVisitedSuccess(data));
	} catch (error) {
		dispatch(
			changeVisitedFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const changeToOnHold = (id) => async (dispatch) => {
	try {
		console.log('11223344' + id);
		dispatch(changeOnHoldStart());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/changeOnHold`,
			{ id },
			config
		);
		console.log(data);
		dispatch(changeOnHoldSuccess(data));
	} catch (error) {
		dispatch(
			changeOnHoldFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const changeSlot = (id,slot) => async (dispatch) => {
	try {
		console.log('11223344' + id);
		dispatch(changeSlotStart());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/changeSlot`,
			{ id,slot },
			config
		);
		console.log(data);
		dispatch(changeSlotSuccess(data));
	} catch (error) {
		dispatch(
			changeSlotFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getUserDataByCategory = (mobileno) => async (dispatch) => {
	try {
		console.log('11223344' + mobileno);
		dispatch(getUserDataByCategoryRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/getuserByCategory`,
			{ mobileno },
			config
		);

		console.log(data);
		dispatch(getUserDataByCategorySuccess(data));
	} catch (error) {
		dispatch(
			getUserDataByCategoryFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getUserDataInSearchPatient = (mobileno) => async (dispatch) => {
	try {
		console.log('11223344' + mobileno);
		dispatch(getUserDataInSearchPatientsRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/getuserByCategory`,
			{ mobileno },
			config
		);

		console.log(data);
		dispatch(getUserDataInSearchPatientsSuccess(data));
	} catch (error) {
		dispatch(
			getUserDataInSearchPatientsFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};


export const getUserDataByCategoryForUser = (mobileno) => async (dispatch) => {
	try {
		console.log('11223344' + mobileno);
		dispatch(getUserDataByCategoryRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/getuserByCategoryForUser`,
			{ mobileno },
			config
		);

		console.log(data);
		dispatch(getUserDataByCategorySuccess(data));
	} catch (error) {
		dispatch(
			getUserDataByCategoryFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const adminCreateBooking =
	(
		name,
		doctorName,
		specialityName,
		speciality,
		doctor,
		slot,
		slotCount,
		userPhone,
		user1
	) =>
	async (dispatch) => {
		try {
			const currDate = new Date(); // Get current date
			const date = currDate.getDate();
			const month = currDate.getMonth() + 1; // Month index starts from 0
			const year = currDate.getFullYear();
    		var scheduledDate = date + " / " + month + " / " + year;
			var day_num = currDate.getDay(currDate);
			console.log(day_num);
			const weekday = [
				'sunday',
				'monday',
				'tuesday',
				'wednesday',
				'thursday',
				'friday',
				'saturday',
			];
			console.log(weekday[day_num]);
			const day = weekday[day_num];
			console.log(day);
			console.log(user1.data.data);
			dispatch(adminCreateBookingStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/admin/adminCreateBooking`,
				{
					name: name,
					specialityName: specialityName,
					doctorName: doctorName,
					speciality: speciality,
					doctor: doctor,
					scheduledDate: scheduledDate,
					slot: slot,
					slotCount: slotCount,
					day: day,
					userPhone: userPhone,
					user1:user1.data.data,
				},
				config
			);
			console.log(data + 'review123');
			dispatch(adminCreateBookingSuccess(data));
		} catch (error) {
			const message =
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message;
			if (message === 'Not authorized, token failed') {
				localStorage.removeItem('persist:root');
				dispatch(logout());
			}
			dispatch(adminCreateBookingFailure(message));
		}
	};
