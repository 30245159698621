/** @format */

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userLoginRedux from "./userLogin";
import userRegisterRedux from "./userRegister";
import productReviewReducer from "./userReview";
import ProductReviewGetReducer from "./getReviews";
import userUpdateReducer from "./userUpdate";
import UpdatePasswordReducer from "./updatePassword";
import createSpecialityReducer from "./adminRedux/createSpeciality";
import getSpecialityReducer from "./adminRedux/getSpeciality";
import createReviewReducer from "./adminRedux/createReview";
import getReviewReducer from "./adminRedux/getReviews";
import adminLoginReducer from "./adminRedux/adminLogin";
import createDoctorReducer from "./adminRedux/createDoctor";
import getDoctorfromSpecialityReducer from "./getDoctorfromSpeciality";
import UserReviewGetReducer from "./getUserReview";
import TimeSlotGetReducer from "./getTimeSlots";
import createBookingReducer from "./createBooking";
import getPatientBookingReducer from "./getPatientBooking";
import doctorBookingGetReducer from "./adminRedux/getBooking";
import getUserdataReducer from "./getUser";
import deleteBookingReducer from "./deleteBooking";
import changeVisitedReducer from "./adminRedux/changeVisited";
import updateReviewReducer from "./updateReview";
import changeOnHoldReducer from "./adminRedux/changeOnHold";
import changeSlotReducer from "./adminRedux/changeSlot";
import adminCreateBookingReducer from "./adminRedux/adminCreateBooking";
import getUserDataByCategoryReducer from "./adminRedux/getUserDataByCategory";
import DoctorGetMyAppointmentsReducer from  "./doctorRedux/getMyAppointments";
import DoctorGetMyTimeSlotsReducer from "./doctorRedux/getMyTimeslots";
import getUserDataInSearchPatientReducer from "./adminRedux/getUserDataInSearchPatients"
import doctorLoginReducer from "./doctorRedux/doctorLogin";

import createPrescriptionReducer from "./prescriptionRedux/createPrescription";
import editPrescriptionReducer from "./prescriptionRedux/editPrescription";
import getAllTitlesReducer from "./prescriptionRedux/getAllTitles";
import viewPatientHistoryReducer from "./prescriptionRedux/viewPatientHistory";
import viewPrescriptionReducer from "./prescriptionRedux/viewPrescription";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  userLogin: userLoginRedux,
  userRegister: userRegisterRedux,
  userReviewCreate: productReviewReducer,
  userReviewsAll: ProductReviewGetReducer,
  userUpdate: userUpdateReducer,
  UpdatePassword: UpdatePasswordReducer,
  createSpeciality: createSpecialityReducer,
  getSpeciality: getSpecialityReducer,
  adminReviewCreate: createReviewReducer,
  adminReviewGet: getReviewReducer,
  adminLogin: adminLoginReducer,
  createDoctor: createDoctorReducer,
  getDoctor: getDoctorfromSpecialityReducer,
  userReview: UserReviewGetReducer,
  getTimeSlots: TimeSlotGetReducer,
  createBooking: createBookingReducer,
  getPatientBooking: getPatientBookingReducer,
  getDoctorBooking: doctorBookingGetReducer,
  getUser: getUserdataReducer,
  deleteBooking: deleteBookingReducer,
  changeVisited: changeVisitedReducer,
  updateReview: updateReviewReducer,
  changeOnHold: changeOnHoldReducer,
  changeSlot: changeSlotReducer,
  adminCreateBooking: adminCreateBookingReducer,
  getUserdataByCategory: getUserDataByCategoryReducer,
  DoctorGetMyAppointments:DoctorGetMyAppointmentsReducer,
  DoctorGetMyTimeSlots:DoctorGetMyTimeSlotsReducer,
  getUserDataInSearchPatient:getUserDataInSearchPatientReducer,
  doctorLogin:doctorLoginReducer,
  createPrescription:createPrescriptionReducer,
  editPrescription:editPrescriptionReducer,
  getAllTitles:getAllTitlesReducer,
  viewPatientHistory:viewPatientHistoryReducer,
  viewPrescription:viewPrescriptionReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const adminInfoFromStorage = localStorage.getItem("adminInfo")
  ? JSON.parse(localStorage.getItem("adminInfo"))
  : null;
const doctorInfoFromStorage = localStorage.getItem("doctorInfo")
  ? JSON.parse(localStorage.getItem("doctorInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  adminLogin: { adminInfo: adminInfoFromStorage },
  doctorLogin: { doctorInfo: doctorInfoFromStorage },
};
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  initialState: initialState,
});

export let persistor = persistStore(store);
