import { createSlice } from "@reduxjs/toolkit";

const getMyAppointmentsReducer = createSlice({
  name: "getMyAppointments",
  initialState: {},
  reducers: {
    getMyAppointmentsRequest: (state) => {
      state.loading = true;
    },
    getMyAppointmentsSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getMyBookings = action.payload;
      state.error = false;
      state.success = true;
    },
    getMyAppointmentsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMyAppointmentsReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getMyAppointmentsRequest,
  getMyAppointmentsSuccess,
  getMyAppointmentsFailure,
  getMyAppointmentsReset,
} = getMyAppointmentsReducer.actions;
export default getMyAppointmentsReducer.reducer;
