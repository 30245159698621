import Message from '../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from 'react-bootstrap/Pagination';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import Form from 'react-bootstrap/Form';

const DoctorLeavesScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  return (
    <>
      <div className="row">
          <div className="col-12 card col-md-6 mt-5">
            <h4 className="header-center">Apply Leaves</h4>
            <div className="mt-5 row">
              <label className='ml-3 form-label col-md-3' htmlFor='name'>
                <b>Select Date: </b>
                <span className='text-danger'>
                  <b>*</b>
                </span>
              </label>    
              <DateRangePicker
                initialSettings={{ startDate: '01/01/2020', endDate: '01/15/2020' }}
              >
                <input className='form-control col-md-8' type="text"/>
              </DateRangePicker>
            </div>
            <div className="mt-5 row">
              <label className='ml-3 form-label col-md-3' htmlFor='name'>
                <b>Reason for leave: </b>
                <span className='text-danger'>
                  <b>*</b>
                </span>
              </label>    
              <input
                className='form-control col-md-8'
                id='desc'
                type='text'
                placeholder='Enter cause'
                required='required'
                // value={name}
                // onChange={(e) => setName(e.target.value)}
					    />
            </div>
            <div className="mt-5 mb-5">
              <button className="btn btn-success offset-5">Apply</button>
            </div>
          </div>
          <div className="col-12 card col-md-6 mt-5">
            <h4 className="header-center">Cancel Leaves</h4>
            <div className="tableData">
              <div className="row tableHeaders mt-3 mb-3">
                <div className="col-7">
                  Description
                </div>
                <div className="col-3">
                  Date
                </div>
                <div className="col-1">
                  
                </div>
              </div>
              <div className="row">
                <div className="mt-2 col-6">
                  esgrdhtjgkhgfdgfsdghjkbhgfdfghjk
                </div>
                <div className="mt-2 col-3 offset-1">
                  23/10/2022
                </div>
                <div className="mt-2 col-1">
                  <button className="btn btn-danger"><i className='fa fa-trash-o'></i></button>
                </div>
                <div className="mt-2 col-6">
                  esgrdhtjgkhgfdgfsdghjkbhgfdfghjk
                </div>
                <div className="mt-2 col-3 offset-1">
                  23/10/2022
                </div>
                <div className="mt-2 col-1">
                  <button className="btn btn-danger"><i className='fa fa-trash-o'></i></button>
                </div>
                <div className="mt-2 col-6">
                  esgrdhtjgkhgfdgfsdghjkbhgfdfghjk
                </div>
                <div className="mt-2 col-3 offset-1">
                  23/10/2022
                </div>
                <div className="mt-2 col-1">
                  <button className="btn btn-danger"><i className='fa fa-trash-o'></i></button>
                </div>
                <div className="mt-2 col-6">
                  esgrdhtjgkhgfdgfsdghjkbhgfdfghjk
                </div>
                <div className="mt-2 col-3 offset-1">
                  23/10/2022
                </div>
                <div className="mt-2 col-1">
                  <button className="btn btn-danger"><i className='fa fa-trash-o'></i></button>
                </div>
                <div className="mt-2 col-6">
                  esgrdhtjgkhgfdgfsdghjkbhgfdfghjk
                </div>
                <div className="mt-2 col-3 offset-1">
                  23/10/2022
                </div>
                <div className="mt-2 col-1">
                  <button className="btn btn-danger"><i className='fa fa-trash-o'></i></button>
                </div>
              </div>
            </div>
            <Pagination className="offset-2 mt-3" size="lg">
              <Pagination.Prev />
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          </div>
      </div>
      <div className="row">
          <div className="col-12 card col-md-6 mt-5">
            <h4 className="header-center">Block Slots</h4>
            <div className="mt-5 row">
              <label className='ml-3 form-label col-md-3' htmlFor='name'>
                <b>Select Date: </b>
                <span className='text-danger'>
                  <b>*</b>
                </span>
              </label>    
              <DropdownButton
                className="col-2 btn"
                key="down-centered"
                id={`dropdown-button-drop-down-centered`}
                drop="down-centered"
                variant="success"
                // title={dateOption}
                title="Choose Date"
                // onSelect={handleDateSelect}
              >
                {/* {days.map((day, index) => (
                  <Dropdown.Item eventKey={day}>{day}</Dropdown.Item>
                ))} */}
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="mt-5 row">
              <label className='ml-3 form-label col-md-3' htmlFor='name'>
                <b>Choose Slot: </b>
                <span className='text-danger'>
                  <b>*</b>
                </span>
              </label>    
               <Button className="col-2 me-1 btn-success"> 10:00 - 11:00</Button>
              <Button className="col-2 me-1 btn-success"> 11:00 - 12:00</Button>
              <Button className="col-2 me-1 btn-danger"> 12:00 - 1:00</Button>
              <Button className="col-2 me-1 btn-success">
                1:00 - 2:00
              </Button>
              <Button className="col-2 me-1 mt-2 btn-success offset-3"> 3:00 - 4:00</Button>
              <Button className="col-2 me-1 mt-2 btn-success"> 4:00 - 5:00</Button>
              <Button className="col-2 me-1 mt-2 btn-danger">
                5:00 - 6:00
              </Button>
              <Button className="col-2 me-1 mt-2 btn-danger"> 6:00 - 7:00</Button>
              <Button className="col-2 me-1 mt-2 btn-success offset-3"> 7:00 - 8:00</Button>
            </div>
            <div className="mt-5 mb-5">
              <button className="btn btn-success offset-5">Apply</button>
            </div>
          </div>
          <div className="col-12 card col-md-6 mt-5">
            <h4 className="header-center">Unblock Slots</h4>
            <div className="mt-5 row">
              <label className='ml-3 form-label col-md-3' htmlFor='name'>
                <b>Select Date: </b>
                <span className='text-danger'>
                  <b>*</b>
                </span>
              </label>    
              <DropdownButton
                className="col-2 btn"
                key="down-centered"
                id={`dropdown-button-drop-down-centered`}
                drop="down-centered"
                variant="success"
                // title={dateOption}
                title="Choose Date"
                // onSelect={handleDateSelect}
              >
                {/* {days.map((day, index) => (
                  <Dropdown.Item eventKey={day}>{day}</Dropdown.Item>
                ))} */}
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
                <Dropdown.Item>23/10/2022</Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="mt-5 row">
              <label className='ml-3 form-label col-md-3' htmlFor='name'>
                <b>Choose Slot: </b>
                <span className='text-danger'>
                  <b>*</b>
                </span>
              </label>    
               <Button className="col-2 me-1 btn-success"> 10:00 - 11:00</Button>
              <Button className="col-2 me-1 btn-success"> 11:00 - 12:00</Button>
              <Button className="col-2 me-1 btn-danger"> 12:00 - 1:00</Button>
              <Button className="col-2 me-1 btn-success">
                1:00 - 2:00
              </Button>
              <Button className="col-2 me-1 mt-2 btn-success offset-3"> 3:00 - 4:00</Button>
              <Button className="col-2 me-1 mt-2 btn-success"> 4:00 - 5:00</Button>
              <Button className="col-2 me-1 mt-2 btn-danger">
                5:00 - 6:00
              </Button>
              <Button className="col-2 me-1 mt-2 btn-danger"> 6:00 - 7:00</Button>
              <Button className="col-2 me-1 mt-2 btn-success offset-3"> 7:00 - 8:00</Button>
            </div>
            <div className="mt-5 mb-5">
              <button className="btn btn-success offset-5">Apply</button>
            </div>
          </div>
      </div>
    </>
  );
};

export default DoctorLeavesScreen;
