/** @format */
import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {getTimeslots} from "../../redux/userApiCalls";
import { getMyAppointments } from "../../redux/doctorRedux/doctorApiCalls";
// import Accordion from 'react-bootstrap/Accordion';
import {Accordion,  AccordionBody,  AccordionHeader,  AccordionItem,} from "reactstrap";
import { getMyAppointmentsReset } from "../../redux/doctorRedux/getMyAppointments";
import Form from "react-bootstrap/Form";

const GetMyAppointmentsScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  // Accordian Collapse
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  //Modal Open
  const [lgShow, setLgShow] = useState(false);

  //Date Format
  const days = [];
  let slotArrayVisited = [];
   const currDate = new Date(); // Get current date
   for (let i = 0; i < 7; i++) {
     const currentDate = new Date(
       currDate.getFullYear(),
       currDate.getMonth(),
       currDate.getDate() + i
     );
     const date = currentDate.getDate();
     const month = currentDate.getMonth() + 1; // Month index starts from 0
     const year = currentDate.getFullYear();
     const formattedDate = date+" / "+month+" / "+year;
     days.push(formattedDate);
   }
   let tempArr = days[0].split("/");
   let dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
  //Retrieving Specialities Data
  useEffect(() => {
    dispatch(
      getMyAppointments(
        "647f70a740297e0aa865bc68",
        "647f723a8aabba0ac67cdd5d",
        dateModified
      )
    );
  }, [dispatch]);

  //Retrieving Bookings Data of selected Doctor
  const data1 = useSelector((state) => state.DoctorGetMyAppointments);
  console.log(data1);
  const { getMyBookings, loading, error, success } = data1;
  console.log(getMyBookings);

  //Date dropdown
  const [dateOption, setDateOption] = useState(days[0]);
  const handleDateSelect = (eventKey) => {
    setDateOption(eventKey);
    // slotArray = [];
    slotArrayVisited = [];
  };

  //Search button function
  const submitHandler = async () => {
    if (dateOption !== days[0]) {
      let tempArr = dateOption.split("/");
      let dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
      console.log(dateModified+"nmnnnnnnnnnnnnnnnnnnnn");
      dispatch(
        getMyAppointments(
          "647f70a740297e0aa865bc68",
          "647f723a8aabba0ac67cdd5d",
          dateModified
        )
      );
    }
  };

  //creating an array containing set of all the visited bookings slots
  console.log(getMyBookings?.data?.data);
  let temp = [];
  for (let i = 0; i < getMyBookings?.data?.data?.length; i++) {
    //filtering bookings and checking if booking is already visited(visited=1)
    if (
      getMyBookings?.data?.data[i]?.visited === 1 &&
      !temp.includes(getMyBookings?.data?.data[i]?.slot)
    )
      slotArrayVisited.push([getMyBookings?.data?.data[i]?.slot]);
    temp.push(getMyBookings?.data?.data[i]?.slot);
  }
  console.log(slotArrayVisited);
  //sorting slots of the array
  slotArrayVisited.sort(function (a, b) {
    return Date.parse("01/01/2013 " + a[0]) - Date.parse("01/01/2013 " + b[0]);
  });
  console.log(slotArrayVisited);
  //loop for all bookings
  for (let i = 0; i < getMyBookings?.data?.data?.length; i++) {
    //filtering bookings and checking if booking is already visited(visited=1)
    if (getMyBookings?.data?.data[i]?.visited === 1) {
      for (let j = 0; j < slotArrayVisited?.length; j++) {
        //checking which slot will the current booking(getMyBookings?.data?.data[i]?) slot belong to
        if (slotArrayVisited[j][0] === getMyBookings?.data?.data[i]?.slot) {
          slotArrayVisited[j].push({
            patientNo: slotArrayVisited[j].length,
            _id: getMyBookings?.data?.data[i]?._id,
            name: getMyBookings?.data?.data[i]?.name,
            slot: getMyBookings?.data?.data[i]?.slot,
            slotCount: getMyBookings?.data?.data[i]?.slotCount,
            visited: getMyBookings?.data?.data[i]?.visited,
            mobileNo: getMyBookings?.data?.data[i]?.mobilenumber,
            onHold: getMyBookings?.data?.data[i]?.onHold,
          });
        }
      }
    }
  }
  console.log(slotArrayVisited);
  const [showPopup, setShowPopup] = useState(false);

  const toggleShowInfoPopup = (e) => {
    setShowPopup(!showPopup);
    e.preventDefault();
    console.log("error");
    dispatch(getMyAppointmentsReset());
  };

  //creating an array containing set of all the bookings slots except onHold
  let slotArrayNotVisited = [];
  temp = [];
  for (let i = 0; i < getMyBookings?.data?.data?.length; i++) {
    if (
      !temp.includes(getMyBookings?.data?.data[i]?.slot) &&
      getMyBookings?.data?.data[i]?.slot !== "onHold"
    ) {
      slotArrayNotVisited.push([getMyBookings?.data?.data[i]?.slot]); //[getMyBookings?.data?.data[i]?.slot]
      temp.push(getMyBookings?.data?.data[i]?.slot);
    }
  }
  slotArrayNotVisited.sort(function (a, b) {
    return Date.parse("01/01/2013 " + a[0]) - Date.parse("01/01/2013 " + b[0]);
  });
  console.log(slotArrayNotVisited);
  for (let i = 0; i < getMyBookings?.data?.data?.length; i++) {
    for (let j = 0; j < slotArrayNotVisited?.length; j++) {
      if (slotArrayNotVisited[j][0] === getMyBookings?.data?.data[i]?.slot) {
        slotArrayNotVisited[j].push({
          patientNo: slotArrayNotVisited[j].length,
          _id: getMyBookings?.data?.data[i]?._id,
          name: getMyBookings?.data?.data[i]?.name,
          slot: getMyBookings?.data?.data[i]?.slot,
          slotCount: getMyBookings?.data?.data[i]?.slotCount,
          visited: getMyBookings?.data?.data[i]?.visited,
          mobileNo: getMyBookings?.data?.data[i]?.mobilenumber,
          onHold: getMyBookings?.data?.data[i]?.onHold,
        });
      }
    }
  }
  return (
    <>
      <Meta />
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}
      <h1 className="offset-md-5">Appointments </h1>
      <div className="row mt-5 mb-3">
        <h6 className="col-2 offset-md-1">
          Select the Date{" "}
          <span className="text-danger">
            <b>*</b>
          </span>
        </h6>
        <DropdownButton
          className="col-2 btn"
          key="down-centered"
          id={`dropdown-button-drop-down-centered`}
          drop="down-centered"
          variant="success"
          title={dateOption}
          onSelect={handleDateSelect}
        >
          {days.map((day, index) => (
            <Dropdown.Item eventKey={day}>{day}</Dropdown.Item>
          ))}
        </DropdownButton>
        <Button
          id="searchButton"
          className="col-2 mt-2 btn-primary"
          onClick={submitHandler}
        >
          Search
        </Button>
      </div>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3 container"
      >
        <Tab eventKey="home" title="Patients to be visited">
          <Container>
            {slotArrayNotVisited.map((slot1, index) => (
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId={index}>{slot1[0]}</AccordionHeader>
                  <AccordionBody accordionId={index}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th scope="col">Patient No</th>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Patient Mobile Number</th>
                        </tr>
                      </thead>
                      {slot1?.slice(1, slot1.length).map(
                        ({
                          _id,
                          name,
                          slot, //onhold
                          slotCount, // onhold:1
                          visited,
                          mobileNo,
                          patientNo,
                          onHold,
                        }) => (
                          <tbody>
                            <tr>
                              <td></td>
                              <td>{patientNo}</td>
                              <td>{name}</td>
                              <td>{mobileNo}</td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            ))}
          </Container>
        </Tab>
        <Tab eventKey="profile" title="Visited Patients">
          <Container>
            {slotArrayVisited.map((slot1, index) => (
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId={index}>{slot1[0]}</AccordionHeader>
                  <AccordionBody accordionId={index}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th scope="col">Patient No</th>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Patient Mobile Number</th>
                          <th scope="col">History</th>
                        </tr>
                      </thead>
                      {slot1
                        ?.slice(1, slot1.length)
                        .map(
                          ({
                            _id,
                            name,
                            slot,
                            slotCount,
                            visited,
                            userPhone,
                            patientNo,
                          }) => (
                            <tbody>
                              <tr>
                                <td></td>
                                <td>{patientNo}</td>
                                <td>{name}</td>
                                <td>{userPhone}</td>
                                <td>
                                  <button
                                    className="btn btn-success"
                                    // onClick={() => handleChangeToVisited(_id)}
                                  >
                                    View prescriptions
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            ))}
          </Container>
        </Tab>
      </Tabs>
    </>
  );
};

export default GetMyAppointmentsScreen;
