import { createSlice } from "@reduxjs/toolkit";

const getMyTimeSlotGetReducer = createSlice({
  name: "TimeSlotGet",
  initialState: {},
  reducers: {
    getMyTimeSlotGetRequest: (state) => {
      state.loading = true;
    },
    getMyTimeSlotGetSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.allTimeSlots = action.payload;
      state.error = false;
      state.success = true;
    },
    getMyTimeSlotGetFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMyTimeSlotGetReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getMyTimeSlotGetRequest,
  getMyTimeSlotGetSuccess,
  getMyTimeSlotGetFailure,
  getMyTimeSlotGetReset,
} = getMyTimeSlotGetReducer.actions;
export default getMyTimeSlotGetReducer.reducer;
