import { createSlice } from "@reduxjs/toolkit";

const getUserDataInSearchPatientsReducer = createSlice({
	name: "getUserDataInSearchPatients",
	initialState: {},
	reducers: {
		getUserDataInSearchPatientsRequest: (state) => {
			state.loading = true;
		},
		getUserDataInSearchPatientsSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.userData = action.payload;
			state.error=false;
			state.success=true
		},
		getUserDataInSearchPatientsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getUserDataInSearchPatientsReset: (state) => {
			return { error:false,success:false };
		},
		getUserDataErrorByCategoryReset: (state) => {
			return { userData: false };
		},
	},
});

export const {
	getUserDataInSearchPatientsRequest,
	getUserDataInSearchPatientsSuccess,
	getUserDataInSearchPatientsFailure,
	getUserDataInSearchPatientsReset,
	getUserDataErrorByCategoryReset
} = getUserDataInSearchPatientsReducer.actions;
export default getUserDataInSearchPatientsReducer.reducer;
