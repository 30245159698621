/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import Errortemplate from '../../Components/Errortemplate';
import { Link } from 'react-router-dom';
import { login } from '../../redux/adminRedux/adminApiCalls';
import Message from '../../Components/Message';
import { loginReset } from '../../redux/adminRedux/adminLogin';

const AdminLoginScreens = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const [username, setusername] = useState('');
	const [password, setPassword] = useState('');
	const [show, setShow] = useState(false);
  	const handleClick = () => setShow(!show);

	const adminLogin = useSelector((state) => state.adminLogin);
	console.log(adminLogin);
	const { loading, error, adminInfo, success } = adminLogin;
	console.log(adminInfo);
	useEffect(() => {
		if (adminInfo) {
			console.log('111');
			history('/admin/appointments');
		}
	}, [history, adminInfo]);
	const submitHandler = (e) => {
		if(username==""){
			alert("Username cannot be empty")
		}
		else if(password==""){
			alert("Password cannot be empty")
		}
		else{
			e.preventDefault();
			dispatch(login(username, password));
		}
	};
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(loginReset());
	};

	return (
		<>
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{success && (
				<Message variant='success'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{'Succesfully logged in'}
				</Message>
			)}

			<div className='row row-content'>
				<div className='col-12 col-md-4 offset-md-4 loginForm'>
					<h2 className='loginHeader'>Login</h2>
					<form
						className='form-group'
						novalidate
						id='user-form'
						onSubmit={submitHandler}>
						<div className='form-group'>
							<label for='username' className='col-md-12 col-form-label'>
								<b>Username</b>{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<div className='col-md-12'>
								<input
									type='text'
									className='form-control'
									id='username'
									name='username'
									placeholder='Enter your username'
									required
									onChange={(e) => setusername(e.target.value)}
								/>
							</div>
						</div>
						<div className='form-group'>
							<label for='password' className='col-md-12 col-form-label'>
								<b>Password</b>{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<div className='col-md-12 flex321'>
								<input
									
									className='form-control'
									id='password'
									name='password'
									placeholder='••••••••'
									required
									onChange={(e) => setPassword(e.target.value)}
									type={show ? "text" : "password"}
								/>
								<button type="button" className="close" onClick={handleClick}>
                  {show==1 ? <i class="fa fa-eye ppppp" aria-hidden="true"></i> : <i class="fa fa-eye-slash ppppp" aria-hidden="true"></i>}
                </button>
							</div>
						</div>
						<div className='form-group'>
							<button
								type='submit'
								className='btn btn-success mt-5 offset-2 col-8'>
								<b>Log in</b>
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AdminLoginScreens;
