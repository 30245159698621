/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout1 } from '../redux/userApiCalls';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
const Header = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const userLogin = useSelector((state) => state.userLogin);
	const { loading, userInfo } = userLogin;
	console.log(userInfo?.user?.dob + 'user logged in');

	const adminLogin = useSelector((state) => state.adminLogin);
	const { error, adminInfo } = adminLogin;
	console.log(adminInfo?.user?.isAdmin + 'admin logged in');

	var isAdminLoggedIn = false;
	if (adminInfo?.user?.isAdmin) {
		isAdminLoggedIn = true;
	}

	const logoutHandler = () => {
		isAdminLoggedIn = false;
		isLoggedIn = false;
		history('/');
		window.location.reload();
		dispatch(logout1());
	};
	let isLoggedIn = false;
	if (userInfo || isAdminLoggedIn) isLoggedIn = true;
	console.log(userInfo);
	// console.log(user.userInfo === null ? "yes" : "no");
	return (
		<header className=''>
			{!isAdminLoggedIn ? (
				<Navbar bg='dark' variant='dark' expand='lg'>
					<Container>
						<Navbar.Brand href='/' className='offset-md-1'>
							<img
								alt='No Img'
								src='/logo3.png'
								width='70'
								height='60'
								className='d-inline-block align-top'
							/>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							<Nav className='me-auto row'>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav' href='/'>
									About Us
								</Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav' href='/booking'>
									Book Appointment
								</Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav' href='/viewappointments'>
									My Appointments
								</Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav' href='/feedbacks'>
									Feedbacks
								</Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
								{/* {isLoggedIn ? <a href="/login" role="button" onClick={logoutHandler} className=" btn btn-danger"><i className="fa fa-sign-out"></i>{'  '}Logout</a> : <Nav.Link className="linksNav" href="/login">Login</Nav.Link>} */}
							</Nav>
							<Nav className='d-none d-md-block'>
								{isLoggedIn ? (
									<Nav.Link className='linksNav'>
										<Dropdown>
											<Dropdown.Toggle variant='secondary' id='dropdown-basic'>
												<i className='fa fa-user'></i> My Profile
											</Dropdown.Toggle>

											<Dropdown.Menu>
											<Dropdown.Item>
													<p>{userInfo?.user?.name}</p>
													<b>{userInfo?.user?.mobilenumber}</b>
												</Dropdown.Item>

												<LinkContainer to='/updatepassword'>
													<Dropdown.Item>Change Password</Dropdown.Item>
												</LinkContainer>

												<Dropdown.Item onClick={logoutHandler}>
													<a className='btn btn-danger' role='button'>
														<i className='fa fa-sign-out'></i>
														{'  '}Logout
													</a>
												</Dropdown.Item>

												{/* <Dropdown.Item><a href="/login" role="button" onClick={logoutHandler} className=" btn btn-danger"><i className="fa fa-sign-out"></i>{'  '}Logout</a></Dropdown.Item> */}
											</Dropdown.Menu>
										</Dropdown>
									</Nav.Link>
								) : (
									<Nav.Link className='linksNav' href='/login'>
										Login
									</Nav.Link>
								)}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			) : (
				<Navbar bg='dark' variant='dark' expand='lg'>
					<Navbar.Brand href='/admin/appointments' className='offset-md-1'>
						<img
							alt='No Img'
							src='/logo3.png'
							width='70'
							height='60'
							className='d-inline-block align-top'
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='me-auto flex1'>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							{/* <Nav.Link className='linksNav' href='/admin/home'>
								Home
							</Nav.Link> */}
							<Nav.Link className='linksNav' href='/admin/appointments'>
								Appointments
							</Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav' href='/admin/bookappointment'>
								Book Appointment
							</Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav' href='/admin/addSpeciality'>
								Add Speciality
							</Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav' href='/admin/addDoctor'>
								Add Doctor
							</Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav' href='/admin/feedbacks'>
								Feedbacks
							</Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							<Nav.Link className='linksNav' href='/admin/searchPatients'>
								Search Patients
							</Nav.Link>
							<Nav.Link className='linksNav d-none d-md-block'></Nav.Link>
							{isLoggedIn ? (
								<a
									href='/login'
									role='button'
									onClick={logoutHandler}
									className=' btn btn-danger'>
									<i className='fa fa-sign-out'></i>
									{'  '}Logout
								</a>
							) : (
								<Nav.Link className='linksNav' href='/admin/login'>
									Login
								</Nav.Link>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			)}
			{/* <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
				<Container>
					<LinkContainer to="/">
						<Navbar.Brand>About Us</Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<LinkContainer to="/booking">
						<Navbar.Brand>Book Appointment</Navbar.Brand>
					</LinkContainer>
					<LinkContainer to="/viewappointments">
						<Navbar.Brand>My Appointments</Navbar.Brand>
					</LinkContainer>
					<LinkContainer to="/prescription">
						<Navbar.Brand>Presciption</Navbar.Brand>
					</LinkContainer>
					<LinkContainer to="/feedbacks">
						<Navbar.Brand>Feedbacks</Navbar.Brand>
					</LinkContainer>
					{isLoggedIn ? <a href="/login" role="button" onClick={logoutHandler} className="btn btn-danger">Logout</a> : <LinkContainer to="/login">
						<Navbar.Brand>Login</Navbar.Brand>
					</LinkContainer>} */}

			{/* <Navbar.Collapse id="basic-navbar-nav">
						<SearchBox />

						<Nav className="ml-auto">
							<LinkContainer to="/cart">
								<Nav.Link>
									<i className="fas fa-shopping-cart"></i> Cart
								</Nav.Link>
							</LinkContainer>
							{userInfo ? (
								<NavDropdown title={userInfo.name} id="username">
									<LinkContainer to="/profile">
										<NavDropdown.Item>Profile</NavDropdown.Item>
									</LinkContainer>
									<NavDropdown.Item onClick={logoutHandler}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<LinkContainer to="/login">
									<Nav.Link>
										<i className="fas fa-user"></i> Sign In
									</Nav.Link>
								</LinkContainer>
							)}
							{userInfo && userInfo.isAdmin && (
								<NavDropdown title="Admin" id="adminmenu">
									<LinkContainer to="/admin/userlist">
										<NavDropdown.Item>Users</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/productlist">
										<NavDropdown.Item>Products</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/orderlist">
										<NavDropdown.Item>Orders</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							)}
						</Nav>
					</Navbar.Collapse> */}
			{/* </Container>
			</Navbar> */}
			{/* <nav className="nav nav--tours">
				<Link to="/" className="nav__el">
					About Us
				</Link>
			</nav>
			<nav className="nav nav--tours">
				<Link to="/" className="nav__el">
					Book Appointment
				</Link>
			</nav>
			<nav className="nav nav--tours">
				<Link to="/" className="nav__el">
					Prescription
				</Link>
			</nav>
			<nav className="nav nav--tours">
				<Link to="/" className="nav__el">
					Reviews
				</Link>
			</nav>
			<nav className="nav nav--tours">
				<Link to="/" className="nav__el">
					Login
				</Link>
			</nav>
			<nav className="nav nav--tours">
				<Link to="/" className="nav__el">
					Signup
				</Link>
			</nav>
			{/* <nav className="nav nav--user">
				{user?.userInfo ? (
					<>
						<Link to="/" className="nav__el" onClick={logoutHandler}>
							Logout
						</Link>
						<Link to="/me" className="nav__el">
							<img
								src={user?.userInfo?.data?.user?.photo}
								alt="User photo"
								className="nav__user-img"
							/>
							<span>{user?.userInfo?.data?.user?.name}</span>
						</Link>
					</>
				) : (
					<>
						<Link to="/login" className="nav__el">
							Log in
						</Link>
						<Link to="/register" className="nav__el nav__el--cta">
							Sign up
						</Link>
					</>
				)}

				{
			</nav> } */}
		</header>
	);
};

export default Header;
