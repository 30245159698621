/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createDoctor = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createDoctorStart: (state) => {
			state.loading = true;
		},
		createDoctorSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createDoctorStart,
	createDoctorSuccess,
	createDoctorFailure,
	createDoctorReset,
} = createDoctor.actions;
export default createDoctor.reducer;
