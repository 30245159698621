/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createReview = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createReviewStart: (state) => {
			state.loading = true;
		},
		createReviewSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success=true
		},
		createReviewFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createReviewReset: (state, action) => {
			state.error = false;
			state.success=false
		},
	},
});

export const {
	createReviewStart,
	createReviewSuccess,
	createReviewFailure,
	createReviewReset,
} = createReview.actions;
export default createReview.reducer;
