/** @format */

import { createSlice } from '@reduxjs/toolkit';

const changeSlot = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		changeSlotStart: (state) => {
			state.loading = true;
		},
		changeSlotSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success=true
		},
		changeSlotFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		changeSlotReset: (state, action) => {
			state.error = false;
			state.success=false
		},
	},
});

export const {
	changeSlotStart,
	changeSlotSuccess,
	changeSlotFailure,
	changeSlotReset,
} = changeSlot.actions;
export default changeSlot.reducer;
