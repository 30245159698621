/** @format */

import { createSlice } from '@reduxjs/toolkit';

const specialityGetReducer = createSlice({
	name: 'specialityGet',
	initialState: {},
	reducers: {
		specialityGetRequest: (state) => {
			state.loading = true;
		},
		specialityGetSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getSpecialityAll = action.payload;
			state.error = false;
			state.success = true;
		},
		specialityGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		specialityGetReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	specialityGetRequest,
	specialityGetSuccess,
	specialityGetFailure,
	specialityGetReset,
} = specialityGetReducer.actions;
export default specialityGetReducer.reducer;
