import { createSlice } from "@reduxjs/toolkit";

const adminCreateBooking = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		adminCreateBookingStart: (state) => {
			state.loading = true;
		},
		adminCreateBookingSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success=true
		},
		adminCreateBookingFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		adminCreateBookingReset: (state, action) => {
			state.success=false
			state.error = false;
		},
	},
});

export const {
	adminCreateBookingStart,
	adminCreateBookingSuccess,
	adminCreateBookingFailure,
	adminCreateBookingReset
} = adminCreateBooking.actions;
export default adminCreateBooking.reducer;
