/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Errortemplate from '../../Components/Errortemplate';
// import { Col, Row } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import {
	updateUserProfileByAdmin,
	getSpecialities,
	getDoctorFromSpeciality,
	getTimeslots,
} from '../../redux/userApiCalls';
import { Col, Row, Container } from 'react-bootstrap';
import {
	getUserDataByCategory,
	adminCreateBooking,
} from '../../redux/adminRedux/adminApiCalls';
import Message from '../../Components/Message';
// import {bookingListScreen} from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from '../../redux/adminRedux/getUserDataByCategory';
import { UserBookingGetReset } from '../../redux/getPatientBooking';
import { userUpdateProfileReset } from '../../redux/userUpdate';
import { adminCreateBookingReset } from '../../redux/adminRedux/adminCreateBooking';

const OnSpotAppointmentsScreens = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [lgShow, setLgShow] = useState(false);
  const [mno, setMno] = useState("");
  const handleSearchPatient = (e) => {
    e.preventDefault();
    setUserOption("Select a user");
    setSpecialityOption("hoose Speciality");
    setDoctorOption("Choose Doctor");
    setDoctorSelected(false);
    setTextVis("d-none offset-md-3 mt-3 text-danger");
    dispatch(getUserDataByCategory(mno));
  };
  const data1 = useSelector((state) => state.getUserdataByCategory);
  const userData = data1.userData;
  const { error, loading, success } = data1;
  const [click, setClick] = useState(0);
  console.log(userData);

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setdob] = useState(new Date());
  const addUserOnSubmit = (e) => {
    e.preventDefault();
    console.log(name + " " + gender + " " + dob + " " + mno);
    var letters = /^[A-Za-z\s]+$/;
    if (name === "") {
      alert("Username cannot be empty");
    } else if (!name.match(letters)) {
      alert("Username must contain only alphabets");
    } else if (gender === "") {
      alert("Please select gender, gender cannot be empty");
    } else if (dob === "") {
      alert("Please select dob, dob cannot be empty");
    } else if (new Date(dob) >= new Date(new Date())) {
      alert("Please select proper dob, dob should not exceed todays date");
    } else {
      dispatch(updateUserProfileByAdmin(name, gender, dob, mno));
      setName("");
      setGender("");
      setdob("");
      setClick(1);
    }
  };
  const users_array = [];
  users_array.push({
    name: userData?.data?.data?.name,
    id: userData?.data?.data?.bookingId,
  });
  for (
    let i = 0;
    i < userData?.data?.data?.registered_patients_array?.length;
    i++
  ) {
    users_array.push({
      name: userData?.data?.data?.registered_patients_array[i]?.name,
      id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
    });
  }
  console.log(users_array);
  const [userOption, setUserOption] = useState("Select a user");
  const [doctorSelected, setDoctorSelected] = useState(false);
  let flag = false;
  const [textVis, setTextVis] = useState("d-none offset-md-3 mt-3 text-danger");
  const handleUserSelect = (eventKey) => {
    setTextVis("d-none offset-md-3 mt-3 text-danger");
    setUserOption(eventKey);
    flag = false;
    for (let i = 0; i < users_array.length; i++) {
      if (eventKey === users_array[i].name && users_array[i].id !== "") {
        flag = true;
        break;
      }
    }
    if (flag) {
      setTextVis("d-block offset-md-3 mt-3 text-danger");
    }
    setSpecialityOption("Choose Specialty");
    setSpecialityId("");
    setDoctorOption("Choose Doctor");
    setSlotOption();
    setSlotCount(0);
    setDoctorSelected(false);
  };
  const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
  let allSpecialities = [];
  if (getSpecialityAll) {
    if (getSpecialityAll.data) {
      if (getSpecialityAll.data.data) {
        for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
          allSpecialities.push(getSpecialityAll.data.data[i]);
        }
      }
    }
  }

  console.log(allSpecialities);

  const [specialityOption, setSpecialityOption] = useState("Choose Specialty");
  const [specialityId, setSpecialityId] = useState("");
  const handleSpecialitySelect = (eventKey) => {
    if (userOption === "Select a user") {
      alert("User needs to be selected first, Please select user");
    } else {
      let arr = [];
      arr = eventKey.split("/");
      setSpecialityOption(arr[0]);
      setSpecialityId(arr[1]);
      setDoctorOption("Choose Doctor");
      setSlotOption();
      setSlotCount(0);
      setDoctorSelected(false);
      dispatch(getDoctorFromSpeciality(arr[0]));
    }
  };
  const currDate = new Date(); // Get current date
  const date = currDate.getDate();
  const month = currDate.getMonth() + 1; // Month index starts from 0
  const year = currDate.getFullYear();
  const dateOption = date + " / " + month + " / " + year;
  let allDoctors = [];
  const [doctorOption, setDoctorOption] = useState("Choose Doctor");
  const [doctorId, setDoctorId] = useState("");
  const handleDoctorSelect = (eventKey) => {
    let arr = [];
    arr = eventKey.split("/");
    setDoctorId(arr[1]);
    setDoctorOption(arr[0]);
    if (userOption === "Select a user") {
      alert("User needs to be selected first, Please select user");
    } else if (specialityOption === "Choose Specialty") {
      alert("Speciality needs to be selected first, Please select Speciality");
    } else {
      setSlotOption();
      setSlotCount(0);
      setDoctorSelected(true);
      dispatch(getTimeslots(arr[0], specialityOption, dateOption));
    }
  };
  const { getDoctorfromSpeciality } = useSelector((state) => state.getDoctor);
  if (getDoctorfromSpeciality) {
    if (getDoctorfromSpeciality.data[0]) {
      console.log(getDoctorfromSpeciality.data);
      if (getDoctorfromSpeciality.data[0].doctorsArray) {
        for (
          let i = 0;
          i < getDoctorfromSpeciality.data[0].doctorsArray.length;
          i++
        ) {
          allDoctors.push(getDoctorfromSpeciality.data[0].doctorsArray[i][0]);
        }
      }
    }
  }
  console.log(allDoctors);

  const [slotOption, setSlotOption] = useState();
  const [slotCount, setSlotCount] = useState(0);
  const handleSlotSelect = (slot, slotcount) => {
    setSlotOption(slot);
    setSlotCount(slotcount + 1);
  };
  const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
  let timeSlots = [];
  let isEmpty = true;
  for (let i = 0; i < allTimeSlots?.length; i++) {
    isEmpty = allTimeSlots[i].isEmpty;
    timeSlots.push({
      isEmpty: isEmpty,
      timeSlot: allTimeSlots[i].timeSlot,
      curCount: allTimeSlots[i].curCount,
      maxCount: allTimeSlots[i].maxCount,
    });
  }
  console.log(timeSlots);

  const submitHandler = async () => {
    if (userOption === "Select a user") {
      alert("User needs to be selected first, Please select user");
    } else if (specialityOption === "Choose Specialty") {
      alert("Speciality needs to be selected first, Please select Speciality");
    } else if (doctorOption === "Choose Doctor") {
      alert("Doctor needs to be selected first, Please select Doctor");
    } else if (slotOption === undefined) {
      alert("Please select a vaild slot");
    } else {
      dispatch(
        adminCreateBooking(
          userOption,
          doctorOption,
          specialityOption,
          specialityId,
          doctorId,
          slotOption,
          slotCount,
          mno,
          userData
        )
      );
      alert(
        `The ${slotCount}th appointment in the ${slotOption}th slot is successfully scheduled.`
      );
      setUserOption("Select a user");
      setSpecialityOption("Choose Specialty");
      setDoctorOption("Choose Doctor");
      setSpecialityId("");
      setDoctorId("");
      setSlotOption();
      setSlotCount(0);
      setDoctorSelected(false);
    }
  };
  const adduserinfo = useSelector((state) => state.userUpdate);
  const adduserinfoerror = adduserinfo.error;
  const adduserinfosuccess = adduserinfo.success;
  const bookingallinfo = useSelector((state) => state.adminCreateBooking);
  const bookingerror = bookingallinfo.error;
  const createdbookingloading = bookingallinfo.loading;
  const bookinguserInfo = bookingallinfo.userInfo;
  const bookingsuccess = bookingallinfo.success;
  const [showPopup, setShowPopup] = React.useState(false);

  const toggleShowInfoPopup = (e) => {
    setShowPopup(!showPopup);
    e.preventDefault();
    console.log("error");
    dispatch(getUserDataByCategoryReset());
  };
  const [showPopup1, setShowPopup1] = React.useState(false);

  const toggleShowInfoPopup1 = (e) => {
    setShowPopup1(!showPopup1);
    e.preventDefault();
    console.log("error");
    dispatch(userUpdateProfileReset());
  };
  const [showPopup2, setShowPopup2] = React.useState(false);

  const toggleShowInfoPopup2 = (e) => {
    setShowPopup2(!showPopup2);
    e.preventDefault();
    console.log("error");
    dispatch(adminCreateBookingReset());
  };
  useEffect(() => {
    //dispatch(getSpecialities());
    if (mno !== "") dispatch(getUserDataByCategory(mno));
    // if(mno !== "") {
    //   dispatch(getUserDataByCategory(mno));
    // }
  }, [
    history,
    dispatch,
    click,
    adduserinfosuccess,
    adduserinfoerror,
    bookingsuccess,
    bookingerror,
  ]);
  useEffect(() => {
    dispatch(getSpecialities());
    // if(mno !== "")
    // dispatch(getUserDataByCategory(mno));
    // if(mno !== "") {
    //   dispatch(getUserDataByCategory(mno));
    // }
  }, []);
  return (
    <div className="body">
      <Meta />
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}

      {bookingerror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup2}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {adduserinfoerror}
        </Message>
      )}
      {adduserinfoerror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {adduserinfoerror}
        </Message>
      )}
      {createdbookingloading && <Loader />}
      {bookingsuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup2}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Booking successfully created"}
        </Message>
      )}
      {adduserinfosuccess && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Added new user"}
        </Message>
      )}
      <h1 className="offset-md-5 offset-2">Book Appointment</h1>
      <Container>
        <div className=" mt-5">
          <form className="row form-group" onSubmit={handleSearchPatient}>
            <h6 className="col-md-3">
              Enter Patient's Mobile Number{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
            <input
              className="form-control offset-1 col-10 offset-md-0 col-md-5"
              type="text"
              placeholder="9999999999"
              id="mobilenumber"
              required="required"
              onChange={(e) => setMno(e.target.value)}
              minlength="10"
              maxlength="10"
              pattern="^[0-9]*$"
            />
            <Button
              type="submit"
              id="search"
              className="offset-4 offset-md-1 btn btn-primary"
            >
              Search
            </Button>
          </form>
        </div>
      </Container>
      <Container>
        <div className="card">
          <div className="mb-2 mt-5 row">
            <h6 className="col-md-3 offset-md-1">
              Select a user{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={userOption}
              onSelect={handleUserSelect}
            >
              {users_array.map((users, index) => (
                <Dropdown.Item eventKey={users.name}>
                  {users.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            {users_array.length >= 6 ? (
              <Button
                disabled
                className="btn offset-4 offset-md-0 btn-danger"
                onClick={() => setLgShow(true)}
              >
                Users limit reached
              </Button>
            ) : (
              <Button
                className="btn offset-4 offset-md-0 btn-secondary"
                onClick={() => setLgShow(true)}
              >
                Add User
              </Button>
            )}

            <div id="userErrMsg" className={textVis}>
              <b>
                Selected user already has an appointment. Please select another
                user.
              </b>
            </div>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Add New User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="form-group" onSubmit={addUserOnSubmit}>
                  <div className="row mt-3">
                    <label className="col-3 col-md-3" htmlFor="name">
                      <b>Name </b>{" "}
                      <span className="text-danger">
                        <b>*</b>
                      </span>
                    </label>
                    <input
                      className="form-control col-8 col-md-8"
                      id="name"
                      type="name"
                      required="required"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="row mt-3">
                    <label htmlFor="gender" className="col-4 col-md-3">
                      <b>Gender </b>{" "}
                      <span className="text-danger">
                        <b>*</b>
                      </span>
                    </label>
                    <div className="row">
                      <b>
                        <Form.Check
                          onChange={() => {
                            setGender("Male");
                          }}
                          checked={gender === "Male"}
                          className="form-check col-5 col-md-4"
                          inline
                          label="Male"
                          type="radio"
                          id={`inline-radio-1`}
                        />
                        <Form.Check
                          onChange={() => {
                            setGender("Female");
                          }}
                          checked={gender === "Female"}
                          inline
                          label="Female"
                          className="form-check col-5 col-md-4"
                          type="radio"
                          id={`inline-radio-2`}
                        />
                      </b>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <label htmlFor="date" className="col-md-3">
                      <b>Date of Birth </b>{" "}
                      <span className="text-danger">
                        <b>*</b>
                      </span>
                    </label>
                    <input
                      type="date"
                      className="col-md-4 form-control"
                      selected={dob}
                      value={dob}
                      onChange={(e) => setdob(e.target.value)}
                    />
                  </div>
                  <div>
                    <Button
                      className="offset-5 offset-md-6 mt-5 btn"
                      type="submit"
                    >
                      Add
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            {flag}
          </div>
        </div>
      </Container>

      <Container>
        <div className="card mt-5">
          <div className="row mt-5">
            <h6 className="col-md-3 offset-md-1">
              Select a doctor and speciality{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={specialityOption}
              onSelect={handleSpecialitySelect}
            >
              {allSpecialities.map((specialities, index) => (
                <Dropdown.Item
                  eventKey={
                    specialities.specialization + "/" + specialities._id
                  }
                >
                  {specialities.specialization}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              className="col-md-3 btn"
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={doctorOption}
              onSelect={handleDoctorSelect}
            >
              {allDoctors.map((doctors, index) => (
                <Dropdown.Item eventKey={doctors.name + "/" + doctors._id}>
                  {doctors.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="row mt-5">
            <h6 className="col-md-2 offset-md-1">
              Select a slot{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
          </div>

          <div className="row">
            <div className="offset-1">
              {doctorSelected &&
                timeSlots.map((timeslots, index) =>
                  timeslots.isEmpty ? (
                    // <button
                    // 	onClick={() =>
                    // 		handleSlotSelect(timeslots.timeSlot, timeslots.curCount)
                    // 	}
                    // 	className=' col-md-3 m-1 slotButton slotButton-success'>
                    // 	{timeslots.timeSlot}
                    // 	<br /> Slot no: {timeslots.curCount} <br />
                    // </button>
                    <Form.Check
                      onChange={() =>
                        handleSlotSelect(timeslots.timeSlot, timeslots.curCount)
                      }
                      className="btn btn-success col-md-3 m-1 col-10"
                      type="radio"
                      id={timeslots.timeSlot}
                      label={`${timeslots.timeSlot} available slots: ${
                        timeslots.maxCount - timeslots.curCount
                      } `}
                      checked={slotOption === timeslots.timeSlot}
                    />
                  ) : (
                    <Button
                      className="btn btn-danger col-md-3 col-10 m-1"
                      disabled
                    >
                      {timeslots.timeSlot}
                      No slots avaliable
                    </Button>
                  )
                )}
            </div>
          </div>
        </div>
      </Container>
      {textVis === "d-block offset-md-3 mt-3 text-danger" ? (
        <Button
          disabled
          className="offset-2 col-8 col-md-2 offset-md-5 mt-2 btn-danger"
          onClick={submitHandler}
        >
          Book Appointment
        </Button>
      ) : (
        <Button
          className="offset-2 col-8 col-md-2 offset-md-5 mt-2 btn-secondary"
          onClick={submitHandler}
        >
          Book Appointment
        </Button>
      )}
    </div>
  );
};

export default OnSpotAppointmentsScreens;
