/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createSpeciality = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createSpecialityStart: (state) => {
			state.loading = true;
		},
		createSpecialitySuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success=true
		},
		createSpecialityFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createSpecialityReset: (state, action) => {
			state.error = false;
			state.success=false
		},

	},
});

export const {
	createSpecialityStart,
	createSpecialitySuccess,
	createSpecialityFailure,
	createSpecialityReset,
} = createSpeciality.actions;
export default createSpeciality.reducer;
