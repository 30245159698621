/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createBooking = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createBookingStart: (state) => {
			state.loading = true;
		},
		createBookingSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createBookingFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createBookingReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createBookingStart,
	createBookingSuccess,
	createBookingFailure,
	createBookingReset,
} = createBooking.actions;
export default createBooking.reducer;
