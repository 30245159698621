/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createPrescription = createSlice({
	name: 'prescriptionInfo',
	initialState: {},
	reducers: {
		createPrescriptionStart: (state) => {
			state.loading = true;
		},
		createPrescriptionSuccess: (state, action) => {
			state.loading = false;
			state.prescriptionInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createPrescriptionFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createPrescriptionReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createPrescriptionStart,
	createPrescriptionSuccess,
	createPrescriptionFailure,
	createPrescriptionReset,
} = createPrescription.actions;
export default createPrescription.reducer;
