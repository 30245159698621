import { createSlice } from "@reduxjs/toolkit";

const getUserdataReducer = createSlice({
	name: "getUserdata",
	initialState: {},
	reducers: {
		getUserdataRequest: (state) => {
			state.loading = true;
		},
		getUserdataSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.userData = action.payload;
			state.error=false;
			state.success = true;
		},
		getUserdataFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getUserdataReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	getUserdataRequest,
	getUserdataSuccess,
	getUserdataFailure,
	getUserdataReset,
} = getUserdataReducer.actions;
export default getUserdataReducer.reducer;
