import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import Loader from "./Loader";

const ProductCarousel = () => {
	const loading = 0;

	return loading ? (
		<Loader />
	) : (
		<Carousel pause="hover" className="bg-dark">
			<Carousel.Item key="1">
				<Image src="/download.jpeg" alt="img1" fluid className="center" />
				<Carousel.Caption className="carousel-caption">
					<h2>"Workspace"</h2>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item key="2">
				<Image src="/download.jpeg" alt="img2" fluid className="center" />
				<Carousel.Caption className="carousel-caption">
					<h2>"Workspace"</h2>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item key="3">
				<Image src="/download2.jpeg" alt="img3" fluid className="center" />
				<Carousel.Caption className="carousel-caption">
					<h2>"Workspace"</h2>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
};

export default ProductCarousel;
