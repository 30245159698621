import { createSlice } from "@reduxjs/toolkit";

const UserBookingGetReducer = createSlice({
	name: "UserBookingGet",
	initialState: {},
	reducers: {
		UserBookingGetRequest: (state) => {
			state.loading = true;
		},
		UserBookingGetSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getBookingsall = action.payload;
			state.error=false;
			state.success = true;
		},
		UserBookingGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		UserBookingGetReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	UserBookingGetRequest,
	UserBookingGetSuccess,
	UserBookingGetFailure,
	UserBookingGetReset,
} = UserBookingGetReducer.actions;
export default UserBookingGetReducer.reducer;
