/** @format */

import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	getUserBooking,
	getUserdata,
	deleteBooking,
} from '../redux/userApiCalls';
import Loader from '../Components/Loader';
import Meta from '../Components/Meta';
import ProductCarousel from '../Components/ProductCarousel';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../Components/Message';
import {deleteBookingReset} from "../redux/deleteBooking"

const ViewAppointmentScreen = () => {
	// const loading = 0;
	const dispatch = useDispatch();
	const { userData } = useSelector((state) => state.getUser);
	const { getBookingsall, loading, error, success } = useSelector(
		(state) => state.getPatientBooking
	);
	const data2 = useSelector(
		(state) => state.deleteBooking
	);
	const success1=data2.success
	const error1=data2.error
	const loading1=data2.loading
	const { userInfo } = useSelector((state) => state.userLogin);
	
	console.log(userData);
	console.log(getBookingsall);
	var arr = [];
	arr.push(userData?.data?.data?.bookingId);
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		arr.push(userData?.data?.data?.registered_patients_array[i]?.bookingId);
	}
	useEffect(() => {
		dispatch(getUserBooking(arr));
	}, [dispatch,error,success,userData]);
	
	useEffect(() => {
		dispatch(getUserdata());
	}, [dispatch]);
	const handleDelete = (id) => {
		
		dispatch(deleteBooking(id));
		
		window.location.reload();
	};

	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(deleteBookingReset());
	};

	return (
		<>
			<Meta />
			{error1 && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error1}
				</Message>
			)}
			{/* {loading1 && <Loader />} */}
			{success1 && (
				<Message variant='success'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{'successfully deleted'}
				</Message>
			)}
			<h1 className='heading1'>My Appointments</h1>
			<div className='row'>
				{getBookingsall?.data?.data?.length ? (
					getBookingsall?.data?.data.map((bookings, index) => (
						<div className='card col-12 col-md-5 offset-md-1 mb-3'>
							<div class='card-body'>
								<h5 class='card-title'>{bookings.name}</h5>
								<div className='card-text'>
									<div className='row'>
										<div className='col-4'>Doctor:</div>
										<div className='col-8'>{bookings.doctorName}</div>
									</div>
									<div className='row'>
										<div className='col-4'>Speciality:</div>
										<div className='col-8'>{bookings.specialityName}</div>
									</div>
									<div className='row'>
										<div className='col-4'>Scheduled Date:</div>
										<div className='col-8'>
											{bookings.scheduledDate.split('/').reverse().join('/')}
										</div>
									</div>
									<div className='row'>
										<div className='col-4'>Slot:</div>
										<div className='col-8'>{bookings.slot}</div>
									</div>
									<div className='row'>
										<div className='col-4'>Slot Count:</div>
										<div className='col-8'>{bookings.slotCount}</div>
									</div>
								</div>
								<button
									class='m-2 btn btn-danger'
									onClick={() => handleDelete(bookings._id)}>
									<i className='fa fa-trash-o'></i>
									{'   '}Delete
								</button>
							</div>
						</div>
					))
				) : (
					<h4 className='offset-md-5 mt-5'>No appointments</h4>
				)}
			</div>
		</>
	);
};

export default ViewAppointmentScreen;
