/** @format */

import React, { useRef, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { getUserDataInSearchPatient } from '../../redux/adminRedux/adminApiCalls';
import { getUserBooking } from '../../redux/userApiCalls';
import Message from '../../Components/Message';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getUserDataInSearchPatientsReset,getUserDataErrorByCategoryReset } from '../../redux/adminRedux/getUserDataInSearchPatients';
import { UserBookingGetReset } from '../../redux/getPatientBooking';
const SearchPatientsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [mno, setMno] = useState('');
	const [userOptionClicked, setUserOptionClicked] = useState(false);
	const userbbokingsdata = useSelector((state) => state.getPatientBooking);
	const [searchClicked,setSearchClicked] = useState(false)
	const getBookingsall = userbbokingsdata.getBookingsall;
	const bookingerror = userbbokingsdata.error;
	const bookingsuccess = userbbokingsdata.success;
	const bookingloading = userbbokingsdata.loading;
	let allBookings = [];

	
	console.log(getBookingsall?.data?.data);
	const data1 = useSelector((state) => state.getUserDataInSearchPatient);
	const { userData, loading, error, success } = data1;
	console.log(userData);
	let users_array = [];
	
	if(mno!=='') {
		users_array.push({
			name: userData?.data?.data?.name,
			id: userData?.data?.data?.bookingId,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
			});
		}
		if (getBookingsall) {
			allBookings = getBookingsall?.data?.data;
		}
		console.log(allBookings);
	}
	
	// useEffect(() => {
	// 	// If there's an alert message and the timer isn't running, start the timer
	// 	if ((error) ) {
	// 	//   setTimerRunning(true);
	// 	//   const timer = setTimeout(() => {
	// 	// 	// Clear the alert and stop the timer after 3 seconds
	// 	// 	dispatch(createSpecialityReset());
			
	// 	// 	setTimerRunning(false);
	// 	//   }, 3000);
	
	// 	  // Clean up the timer if the component unmounts or the alert changes
	// 	  dispatch(getUserDataErrorByCategoryReset())
	// 	}
	//   }, [error, dispatch]);	
	const [showPopup, setShowPopup] = React.useState(false);
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOptionClicked(false);
		setSearchClicked(true)
		dispatch(getUserDataInSearchPatient(mno));

		// dispatch(getUserBooking(arr));
	};
	const handelUserSelect = (id) => {
		let arr = [id];
		setUserOptionClicked(true);
		dispatch(getUserBooking(arr));
	};
	useEffect(() => {
		//dispatch(getSpecialities());

		if (mno !== '') {
			dispatch(getUserDataInSearchPatient(mno));
		}
	}, [history, dispatch, bookingerror, bookingsuccess]);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		
		console.log('error');
		dispatch(getUserDataInSearchPatientsReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(UserBookingGetReset());
	};
	console.log(searchClicked)

	return (
		<>
			<Meta />
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			{/* {bookingerror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {bookingerror}
        </Message>
      )} */}
			{bookingloading && <Loader />}
			<h1 className='offset-md-5 offset-2'>Search Patients </h1>
			<Container>
				<div className=' mt-5'>
					<form className='row form-group' onSubmit={handleSearchPatient}>
						<h6 className='col-md-3'>
							Enter Patient's Mobile Number{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
						<input
							className='form-control col-md-5'
							type='text'
							placeholder='9999999999'
							id='mobilenumber'
							required='required'
							onChange={(e) => {setMno(e.target.value);
							setSearchClicked(false)}}
							minlength='10'
							maxlength='10'
							pattern='^[0-9]*$'
						/>
						<button type='submit' className='offset-md-1 btn btn-primary'>
							Search
						</button>
					</form>
				</div>
			</Container>
			<div className='row offset-md-2'>
				{!error && searchClicked && userData &&
					users_array.map((users, index) => (
						<button
							onClick={() => handelUserSelect(users.id)}
							className='usersColor col-md-3 m-1'>
							{users.name}
						</button>
					))}
			</div>
			<div className='row offset-md-2'>
				{!error && allBookings && searchClicked && userData && userOptionClicked ? (
					allBookings?.length ? (
						getBookingsall?.data?.data.map((bookings, index) => (
							<div className='card col-12 col-md-5 offset-md-2 mt-2 mb-3'>
								<div class='card-body'>
									<h5 class='card-title'>{bookings.name}</h5>
									<div className='card-text'>
										<div className='row'>
											<div className='col-4'>Doctor:</div>
											<div className='col-8'>{bookings.doctorName}</div>
										</div>
										<div className='row'>
											<div className='col-4'>Speciality:</div>
											<div className='col-8'>{bookings.specialityName}</div>
										</div>
										<div className='row'>
											<div className='col-4'>Scheduled Date:</div>
											<div className='col-8'>
												{bookings.scheduledDate.split('/').reverse().join('/')}
											</div>
										</div>
										<div className='row'>
											<div className='col-4'>Slot:</div>
											<div className='col-8'>{bookings.slot}</div>
										</div>
										<div className='row'>
											<div className='col-4'>Slot Count:</div>
											<div className='col-8'>{bookings.slotCount}</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<Container>
							<h2>No Bookings</h2>
						</Container>
					)
				) : (
					<div></div>
				)}
			</div>
		</>
	);
};

export default SearchPatientsScreens;
