import { createSlice } from "@reduxjs/toolkit";

const doctorBookingGetReducer = createSlice({
	name: "doctorBookingGet",
	initialState: {},
	reducers: {
		doctorBookingGetRequest: (state) => {
			state.loading = true;
		},
		doctorBookingGetSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getBookingsall = action.payload;
			state.error=false;
			state.success=true
		},
		doctorBookingGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		doctorBookingGetReset: (state) => {
			return { error:false,success:false};
		},
	},
});

export const {
	doctorBookingGetRequest,
	doctorBookingGetSuccess,
	doctorBookingGetFailure,
	doctorBookingGetReset,
} = doctorBookingGetReducer.actions;
export default doctorBookingGetReducer.reducer;
