import axios from "axios";
import {SERVER_URL} from "../App"
import { loginSuccess } from "./userLogin";
import { loginStart } from "./userLogin";
import { loginFailure } from "./userLogin";
import { registerStart } from "./userRegister";
import { registerFailure } from "./userRegister";
import { registerSuccess } from "./userRegister";
import { logout } from "./userLogin";
import { ProductReviewCreateSuccess } from "./userReview";
import { ProductReviewCreateRequest } from "./userReview";
import { ProductReviewCreateFailure } from "./userReview";
import {
  ProductReviewGetRequest,
  ProductReviewGetFailure,
  ProductReviewGetSuccess,
} from "./getReviews";
import { UpdateFailure, UpdateStart, UpdateSuccess } from "./userUpdate";
import { DeleteFailure, DeleteStart, DeleteSuccess } from "./deleteUser";
import {
  UpdatePasswordFailure,
  UpdatePasswordStart,
  UpdatePasswordSuccess,
} from "./updatePassword";
import {
  createBookingStart,
  createBookingFailure,
  createBookingSuccess,
} from "./createBooking";

import {
  specialityGetRequest,
  specialityGetSuccess,
  specialityGetFailure,
} from "./getSpecialities";
import {
  TimeSlotGetRequest,
  TimeSlotGetFailure,
  TimeSlotGetSuccess,
} from "./getTimeSlots";
import { persistor } from "./store";
import {
  getDoctorfromSpecialityFailure,
  getDoctorfromSpecialityRequest,
  getDoctorfromSpecialitySuccess,
  getDoctorfromSpecialityReset,
} from "./getDoctorfromSpeciality";
import {
  UserReviewGetRequest,
  UserReviewGetFailure,
  UserReviewGetSuccess,
  UserReviewGetReset,
} from "./getUserReview";

import {
  UserBookingGetFailure,
  UserBookingGetRequest,
  UserBookingGetSuccess,
} from "./getPatientBooking";

import {
  getUserdataRequest,
  getUserdataSuccess,
  getUserdataFailure,
} from "./getUser";

import {
  deleteBookingFailure,
  deleteBookingSuccess,
  deleteBookingStart,
} from "./deleteBooking";

import {
  reviewUpdateFailure,
  reviewUpdateRequest,
  reviewUpdateSuccess,
} from "./updateReview";

// import { UpdateStart } from "./userUpdate";
// import { UpdateSuccess } from "./userUpdate";
// import { UpdateFailure } from "./userUpdate";
// import { userUpdateProfileReset } from "./userUpdate";
// import { bookingListMyReset } from "./getMyAppointmentss";
// import { tourReviewCreateReset } from "./tourReview";
export const login = (mobileno, password) => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(loginStart());

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
    };
    const data1 = await axios.post(
      `${SERVER_URL}/api/v1/users/login`,
      { mobileno, password },
      config
    );
    console.log(data1);
    const { data } = data1;
    let users = data.user.name + "/";
    for (let i = 0; i < data.user.registered_patients_array.length; i++) {
      users = users + data.user.registered_patients_array[i].name + "/";
    }
    console.log(data);
    localStorage.setItem("token-user", data.token);
    localStorage.setItem("userInfo", JSON.stringify(data));
    // localStorage.setItem("name", data.user.name);
    //localStorage.setItem("users", users);

    // console.log(data.user);
    dispatch(loginSuccess(data));
  } catch (error) {
    console.log(error.response.data.data.message);
    dispatch(
      loginFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const register =
  (name, password, mobilenumber, gender, dob) => async (dispatch) => {
    try {
      dispatch(registerStart());

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "true",
        },
      };
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/users/signup`,
        { name, password, mobilenumber, gender, dob },
        config
      );
      console.log(data);
      dispatch(registerSuccess(data));
    } catch (error) {
      console.log(error);
      dispatch(
        registerFailure(
          error.response && error.response.data.data.message
            ? error.response.data.data.message
            : error.message
        )
      );
    }
  };

export const logout1 = () => (dispatch) => {
  dispatch(logout());
  // dispatch(userUpdateProfileReset());
  // dispatch(bookingListMyReset());
  // dispatch(tourReviewCreateReset());
  localStorage.removeItem("userInfo");
  localStorage.removeItem("adminInfo");
  localStorage.removeItem("token-user");
  localStorage.removeItem("persist:root");
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
};

export const createReview = (rating, reviews) => async (dispatch, getState) => {
  try {
    dispatch(ProductReviewCreateRequest());

    const {
      userLogin: { userInfo },
    } = getState();
    console.log(getState());
    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await axios.post(
      `${SERVER_URL}/api/v1/reviews`,
      { rating: rating, review: reviews },
      config
    );
    console.log(data + "review123");
    dispatch(ProductReviewCreateSuccess());
  } catch (error) {
    const message =
      error.response && error.response.data.data.message
        ? error.response.data.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      localStorage.removeItem("persist:root");
      dispatch(logout());
    }
    dispatch(ProductReviewCreateFailure(message));
  }
};

export const getReviews = () => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(ProductReviewGetRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${SERVER_URL}/api/v1/reviews`,

      config
    );

    console.log(data);
    dispatch(ProductReviewGetSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(
      ProductReviewGetFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const getUserdata = () => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(getUserdataRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${SERVER_URL}/api/v1/users/getuser`,

      config
    );

    console.log(data);
    dispatch(getUserdataSuccess(data));
  } catch (error) {
    dispatch(
      getUserdataFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const updateUserProfile =
  (name, gender, dob, mobno) => async (dispatch) => {
    try {
      dispatch(UpdateStart());
      console.log("getting3221");

      const token = localStorage.getItem("token-user");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log("getting3221asd");

      const { data } = await axios.patch(
        `${SERVER_URL}/api/v1/booking/updateMe`,
        { name, gender, dob, mobno },
        config
      );
      console.log(data);
      dispatch(UpdateSuccess(data));
      dispatch(loginSuccess(data));
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      const message =
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        localStorage.removeItem("persist:root");
        dispatch(logout());
      }
      dispatch(UpdateFailure(message));
    }
  };

export const updateUserProfileByAdmin =
  (name, gender, dob, mobno) => async (dispatch) => {
    try {
      dispatch(UpdateStart());
      console.log("getting3221");

      const token = localStorage.getItem("token-user");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log("getting3221asd");

      const { data } = await axios.patch(
        `${SERVER_URL}/api/v1/booking/updateMeByAdmin`,
        { name, gender, dob, mobno },
        config
      );
      console.log(data);
      dispatch(UpdateSuccess(data));
      dispatch(loginSuccess(data));
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      const message =
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        localStorage.removeItem("persist:root");
        dispatch(logout());
      }
      dispatch(UpdateFailure(message));
    }
  };

// export const deleteUser = (name, gender, dob) => async (dispatch) => {
// 	try {
// 		dispatch(DeleteStart());
// 		console.log("getting3221");

// 		const token = localStorage.getItem("token-user");
// 		const config = {
// 			headers: {
// 				"Content-Type": "application/json",
// 				Authorization: `Bearer ${token}`,
// 			},
// 		};
// 		console.log("getting3221asd");

// 		const { data } = await axios.post(
// 			`${SERVER_URL}/api/v1/users/deleteUser`,
// 			{ name, gender, dob },
// 			config
// 		);
// 		console.log(data);
// 		dispatch(DeleteSuccess(data));
// 		dispatch(loginSuccess(data));
// 		localStorage.setItem("userInfo", JSON.stringify(data));
// 	} catch (error) {
// 		const message =
// 			error.response && error.response.data.data.message
// 				? error.response.data.data.message
// 				: error.message;
// 		if (message === "Not authorized, token failed") {
// 			localStorage.removeItem("persist:root");
// 			dispatch(logout());
// 		}
// 		dispatch(DeleteFailure(message));
// 	}
// };

export const updatePassword = (password) => async (dispatch, getState) => {
  try {
    dispatch(UpdatePasswordStart());
    console.log("getting3221");
    const {
      userLogin: { userInfo },
    } = getState();
    console.log(password);
    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/users/updatePassword`,
      { password },
      config
    );
    console.log(data);
    dispatch(UpdatePasswordSuccess(data));
    dispatch(logout());
    // dispatch(userUpdateProfileReset());
    // dispatch(bookingListMyReset());
    // dispatch(tourReviewCreateReset());
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token-user");
    localStorage.removeItem("persist:root");
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
  } catch (error) {
    const message =
      error.response && error.response.data.data.message
        ? error.response.data.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      localStorage.removeItem("persist:root");
      dispatch(logout());
    }
    dispatch(UpdatePasswordFailure(message));
  }
};

export const getSpecialities = () => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(specialityGetRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${SERVER_URL}/api/v1/booking/getspeciality`,

      config
    );
    console.log(data);
    dispatch(specialityGetSuccess(data));
  } catch (error) {
    dispatch(
      specialityGetFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const getTimeslots = (name, speciality, date) => async (dispatch) => {
  try {
    console.log("11223344");
    console.log(name, speciality, date);
    const tempArr = date.split("/");
    const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
    dateModified.trim();
    console.log(dateModified);
    dispatch(TimeSlotGetRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${SERVER_URL}/api/v1/booking/getTimeSlots/${name}/${speciality}/${dateModified}`,
      config
    );
    console.log(data.data.docs);
    dispatch(TimeSlotGetSuccess(data.data.docs));
  } catch (error) {
    dispatch(
      TimeSlotGetFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const getDoctorFromSpeciality = (speciality) => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(getDoctorfromSpecialityRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${SERVER_URL}/api/v1/booking/getDoctorfromSpeciality/${speciality}`,
      speciality,
      config
    );
    console.log(JSON.stringify(data) + "data");
    dispatch(getDoctorfromSpecialitySuccess(data));
  } catch (error) {
    dispatch(
      getDoctorfromSpecialityFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};
export const getUserReview = () => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(UserReviewGetRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${SERVER_URL}/api/v1/reviews/getmy`,

      config
    );
    // let users = data.user.name + "/";
    // for (let i = 0; i < data.user.registered_patients_array.length; i++) {
    // 	users = data.user.registered_patients_array[i] + "/";
    // }
    // console.log(users);
    // localStorage.setItem("token-user", data.token);
    // localStorage.setItem("name", data.user.name);
    //localStorage.setItem("users", users);

    // console.log(data.user);
    console.log(data);
    dispatch(UserReviewGetSuccess(data));
  } catch (error) {
    dispatch(
      UserReviewGetFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const createBooking =
  (
    name,
    doctorName,
    specialityName,
    speciality,
    doctor,
    scheduledDate,
    slot,
    slotCount,
    day,
    userPhone
  ) =>
  async (dispatch) => {
    try {
      console.log(day);
      dispatch(createBookingStart());
      const token = localStorage.getItem("token-user");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const data = await axios.post(
        `${SERVER_URL}/api/v1/booking`,
        {
          name: name,
          specialityName: specialityName,
          doctorName: doctorName,
          speciality: speciality,
          doctor: doctor,
          scheduledDate: scheduledDate,
          slot: slot,
          slotCount: slotCount,
          day: day,
          userPhone: userPhone,
        },
        config
      );
      console.log(data + "review123");
      dispatch(createBookingSuccess(data));
    } catch (error) {
      const message =
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        localStorage.removeItem("persist:root");
        dispatch(logout());
      }
      dispatch(createBookingFailure(message));
    }
  };

export const getUserBooking = (ids) => async (dispatch) => {
  try {
    console.log(ids + "    11223344");
    dispatch(UserBookingGetRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/booking/getUserBookings`,
      { ids },
      config
    );
    // let users = data.user.name + "/";
    // for (let i = 0; i < data.user.registered_patients_array.length; i++) {
    // 	users = data.user.registered_patients_array[i] + "/";
    // }
    // console.log(users);
    // localStorage.setItem("token-user", data.token);
    // localStorage.setItem("name", data.user.name);
    //localStorage.setItem("users", users);

    // console.log(data.user);
    console.log(data + "56777777");
    dispatch(UserBookingGetSuccess(data));
  } catch (error) {
    dispatch(
      UserBookingGetFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const deleteBooking = (id) => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(deleteBookingStart());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.delete(
      `${SERVER_URL}/api/v1/booking/${id}`,
      config
    );
    // let users = data.user.name + "/";
    // for (let i = 0; i < data.user.registered_patients_array.length; i++) {
    // 	users = data.user.registered_patients_array[i] + "/";
    // }
    // console.log(users);
    // localStorage.setItem("token-user", data.token);
    // localStorage.setItem("name", data.user.name);
    //localStorage.setItem("users", users);

    // console.log(data.user);
    console.log(data);
    dispatch(deleteBookingSuccess(data));
  } catch (error) {
    dispatch(
      deleteBookingFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};

export const updateReview = (id) => async (dispatch) => {
  try {
    console.log("11223344");
    dispatch(reviewUpdateRequest());

    const token = localStorage.getItem("token-user");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.update(
      `${SERVER_URL}/api/v1/reviews/${id}`,
      config
    );
    // let users = data.user.name + "/";
    // for (let i = 0; i < data.user.registered_patients_array.length; i++) {
    // 	users = data.user.registered_patients_array[i] + "/";
    // }
    // console.log(users);
    // localStorage.setItem("token-user", data.token);
    // localStorage.setItem("name", data.user.name);
    //localStorage.setItem("users", users);

    // console.log(data.user);
    console.log(data);
    dispatch(reviewUpdateSuccess(data));
  } catch (error) {
    dispatch(
      reviewUpdateFailure(
        error.response && error.response.data.data.message
          ? error.response.data.data.message
          : error.message
      )
    );
  }
};
