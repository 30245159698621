import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { getReviews, createReview } from "../../redux/adminRedux/adminApiCalls";
import Message from "../../Components/Message";
import {getReviewsGetReset} from "../../redux/adminRedux/getReviews"
import {createReviewReset} from "../../redux/adminRedux/createReview"

const AdminFeedbackScreens = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector((state) => state.adminReviewGet);
  // console.log(data);
  const { getReviewsall, loading, error } = data;
  const [add,setAdd]=useState(0)
  var data2;
  if (getReviewsall) {
    if (getReviewsall.data) {
      if (getReviewsall.data.docs) {
        data2 = getReviewsall.data.docs;
      }
    }
  }

  console.log(data2);
  useEffect(() => {
    dispatch(getReviews());
    // history("/admin/feedbacks");
  }, [dispatch]);
  const handleAdd = (push, id) => {
    // history("/admin/feedbacks");
    dispatch(createReview(push, id));
    window.location.reload();
  };
  const handleDelete = (push, id) => {
    dispatch(createReview(push, id));
	  setAdd(1)
    window.location.reload();
  };
  const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(getReviewsGetReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(createReviewReset());
	};
	const admincreatedReview = useSelector((state) => state.adminReviewCreate);
	const loadinreview =admincreatedReview.loading
	const errorReview=admincreatedReview.error
	const successReview=admincreatedReview.success
	console.log(loadinreview,loading)
 
	
  return (
    <>
      <Meta />
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}
	  {errorReview && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {errorReview}
        </Message>
      )}
      {/* {loadinreview && <Loader />} */}
	  {successReview && (
        <Message variant="success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup1}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {"Successfully"}{add?"Deleted":"Added"}
        </Message>
      )}
      <h1 className="offset-md-5">Feedbacks</h1>
      <div className="row">
        {data2 &&
          data2.map((reviews, index) => (
            <div className="card col-12 col-md-5 offset-md-1">
              <div className="row mt-3">
                <i className="fa fa-user fa-2x col-1"></i>
                <div className="col-5">Name: {reviews.user.name} </div>
                <div className="col-5"> Mobile No: {reviews.phoneNo} </div>
              </div>
              <div className="row mt-3">
                <div className="col-11 offset-1">Rating: {reviews.rating}</div>
                <div className="col-11 offset-1">Review: {reviews.review}</div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-3 offset-3">
                  <button
                    className="btn btn-success"
                    onClick={() => handleAdd(true, reviews._id)}
                  >
                    Add
                  </button>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(false, reviews._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default AdminFeedbackScreens;
